import { lazy } from 'react';
import { ENABLE_COMING_SOON } from '@blank/constants/flags';
import {
  EXPLORE_ROUTE,
  HOME_ROUTE,
  SETTING_ROUTE,
  MINT_ROUTE,
  WHITE_WALL_ROUTE,
  PRIVACY_ROUTE,
  // FAQ_ROUTE,
  // ABOUT_ROUTE,
  COLLECTION_PUBLIC_ROUTE,
  COLLECTION_ROUTE,
  NEW_COLLECTION_ROUTE,
  LAUCNHCARD_COLLECTION,
  ART_WORK_ROUTE,
  NOT_FOUND,
  PRESIGN_ROUTE,
  TERM_OF_SERVICE,
  TERM_OLD,
  EDIT_PROFILE,
  OFFERS,
  AUCTIONS,
  MINT_SCRIPT_ROUTE,
  FEATURED_CREATORS_PAGE_ROUTE,
  FEATURED_COLLECTIONS_PAGE_ROUTE,
  BLOG,
  BLOG_DETAIL,
  LANDING_PAGE,
  EMAIL_VERIFICATION_PAGE,
  SIGNING_UP_PAGE,
  OPEN_EDITION_ASSET_ROUTE,
  PRESIGN_OPEN_EDITION_PAGE,
  OPEN_EDITION_COLLECTION_ROUTE,
} from './routeConstants';

const routeConfigs = [
  {
    path: NOT_FOUND,
    component: lazy(() => import('./NotFound')),
  },
  {
    exact: true,
    path: OPEN_EDITION_ASSET_ROUTE,
    component: lazy(() => import('./OpenEditionAssetPage')),
    breadcrumbsConfig: [
      EXPLORE_ROUTE,
      OPEN_EDITION_COLLECTION_ROUTE,
      OPEN_EDITION_ASSET_ROUTE,
    ],
  },
  {
    path: PRESIGN_OPEN_EDITION_PAGE,
    component: lazy(() => import('./PreSignOpenEdition')),
    breadcrumbsConfig: [],
  },
  {
    exact: true,
    path: SIGNING_UP_PAGE,
    component: lazy(() => import('./SigningUp')),
    breadcrumbsConfig: [],
  },
  {
    exact: true,
    path: EMAIL_VERIFICATION_PAGE,
    component: lazy(() => import('./EmailVerificationPage')),
    breadcrumbsConfig: [],
  },
  {
    exact: true,
    path: BLOG,
    component: lazy(() => import('./Blog')),
    breadcrumbsConfig: [BLOG],
  },
  {
    exact: true,
    path: BLOG_DETAIL,
    component: lazy(() => import('./Blog/BlogDetail')),
    breadcrumbsConfig: [BLOG_DETAIL],
  },
  {
    exact: true,
    path: FEATURED_CREATORS_PAGE_ROUTE,
    component: lazy(() => import('./FeaturedCreators')),
    breadcrumbsConfig: [FEATURED_CREATORS_PAGE_ROUTE],
  },
  {
    exact: true,
    path: FEATURED_COLLECTIONS_PAGE_ROUTE,
    component: lazy(() => import('./FeaturedCollections')),
    breadcrumbsConfig: [FEATURED_COLLECTIONS_PAGE_ROUTE],
  },
  {
    exact: true,
    path: LAUCNHCARD_COLLECTION,
    component: lazy(() => import('./Cassandra')),
    breadcrumbsConfig: [EXPLORE_ROUTE, COLLECTION_PUBLIC_ROUTE],
  },
  {
    exact: true,
    path: EDIT_PROFILE,
    component: lazy(() => import('./Dashboard/Setting')),
    breadcrumbsConfig: [],
  },
  {
    exact: true,
    path: OFFERS,
    component: lazy(() => import('./Dashboard/Offer')),
    breadcrumbsConfig: [],
  },
  {
    exact: true,
    path: AUCTIONS,
    component: lazy(() => import('./Dashboard/Auction')),
    breadcrumbsConfig: [],
  },
  // {
  //   exact: true,
  //   path: ABOUT_ROUTE,
  //   component: lazy(() => import('./About')),
  //   breadcrumbsConfig: [HOME_ROUTE, ABOUT_ROUTE],
  // },
  // {
  //   exact: true,
  //   path: FAQ_ROUTE,
  //   component: lazy(() => import('./FAQ')),
  //   breadcrumbsConfig: [HOME_ROUTE, FAQ_ROUTE],
  // },
  {
    exact: true,
    path: PRIVACY_ROUTE,
    component: lazy(() => import('./Privacy')),
    breadcrumbsConfig: [EXPLORE_ROUTE, PRIVACY_ROUTE],
  },
  {
    exact: true,
    path: TERM_OF_SERVICE,
    component: lazy(() => import('./TermsOfService')),
    breadcrumbsConfig: [EXPLORE_ROUTE, TERM_OF_SERVICE],
  },
  {
    exact: true,
    path: TERM_OLD,
    component: lazy(() => import('./TermsOfServiceOld')),
    breadcrumbsConfig: [EXPLORE_ROUTE, TERM_OLD],
  },
  {
    exact: true,
    path: ART_WORK_ROUTE,
    component: lazy(() => import('./AssetPage')),
    breadcrumbsConfig: [EXPLORE_ROUTE, COLLECTION_ROUTE, ART_WORK_ROUTE],
  },
  {
    exact: true,
    path: WHITE_WALL_ROUTE,
    component: lazy(() => import('./WhiteWall')),
    breadcrumbsConfig: [EXPLORE_ROUTE, WHITE_WALL_ROUTE],
  },
  {
    exact: true,
    path: MINT_ROUTE,
    component: lazy(() => import('./Mint')),
    breadcrumbsConfig: [MINT_ROUTE],
  },
  {
    exact: true,
    path: PRESIGN_ROUTE,
    component: lazy(() => import('./Mint/presign')),
    breadcrumbsConfig: [PRESIGN_ROUTE],
  },
  {
    exact: true,
    path: MINT_SCRIPT_ROUTE,
    component: lazy(() => import('./Mint/script')),
    breadcrumbsConfig: [MINT_SCRIPT_ROUTE],
  },
  {
    exact: true,
    path: SETTING_ROUTE,
    component: lazy(() => import('./Setting')),
    breadcrumbsConfig: [SETTING_ROUTE],
  },
  {
    exact: true,
    path: EXPLORE_ROUTE,
    component: lazy(() => import('./Explore')),
    breadcrumbsConfig: [EXPLORE_ROUTE],
  },
  {
    exact: true,
    path: HOME_ROUTE,
    component: ENABLE_COMING_SOON
      ? lazy(() => import('./ComingSoon'))
      : lazy(() => import('./Home')),
    breadcrumbsConfig: [HOME_ROUTE],
  },
  ENABLE_COMING_SOON
    ? {
        exact: true,
        path: LANDING_PAGE,
        component: lazy(() => import('./Home')),
        breadcrumbsConfig: [LANDING_PAGE],
      }
    : undefined,
  {
    exact: true,
    path: NEW_COLLECTION_ROUTE,
    component: lazy(() => import('./CollectionNew')),
    breadcrumbsConfig: [EXPLORE_ROUTE, NEW_COLLECTION_ROUTE],
  },
  {
    exact: true,
    path: OPEN_EDITION_COLLECTION_ROUTE,
    component: lazy(() => import('./OpenEdition')),
    breadcrumbsConfig: [EXPLORE_ROUTE, OPEN_EDITION_COLLECTION_ROUTE],
  },
  {
    exact: true,
    path: COLLECTION_ROUTE,
    component: lazy(() => import('./Collection')),
    breadcrumbsConfig: [EXPLORE_ROUTE, COLLECTION_ROUTE],
  },
].filter((r) => !!r);

export default routeConfigs;
