import React, { useState } from 'react';

import { Box } from './';

const files = require.context(
  '!svg-sprite-loader!../assets/icons',
  false,
  /.*\.svg$/
);
files.keys().forEach(files);

const Svg = (props) => <Box as="svg" {...props} />;

const Icon = ({ name, hoverName, size = '1em', ...props }) => {
  const [isHover, setIsHover] = useState(false);
  const hoverIcon = hoverName || name;
  const iconName = isHover ? hoverIcon : name;

  return (
    <Svg
      height={size}
      width={size}
      flexBasis={size}
      flexGrow={0}
      flexShrink={0}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      {...props}
    >
      <use xlinkHref={`#${iconName}`}></use>
    </Svg>
  );
};

export default Icon;
