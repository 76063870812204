import React from 'react';
import CopyButton from '@blank/components/CopyButton';
import { truncateAddress } from '@blank/utils';

const UserBreadcrumb = ({ account }) => {
  return (
    <CopyButton
      text={account}
      width="126px"
      textDisPlay={truncateAddress(account)}
    />
  );
};

export default UserBreadcrumb;
