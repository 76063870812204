import React, { useMemo } from 'react';

import { Box, Link, Text } from '../../../atoms';
// import { useRouteMatch } from 'react-router-dom';
import CollectionImage from '../CollectionImage';
import Overlay from '@blank/components/Overlay';
import { WHITE_WALL_ROUTE } from '../../routeConstants';
import { truncateAddress, addressEquals } from '@blank/utils';
import {
  VOLATILITY_COLECTION_V1_ADDRESS,
  VOLATILITY_COLECTION_V2_ADDRESS,
  MARCO_COLLAB_ADDRESS,
  CASSANDRA_ADDRESS,
  SMOKERS_ADDRESS,
  SANDBOX_ADDRESS,
  SZABOTAGE_ADDRESS,
} from '@blank/constants/network';
// import CollectionImage from '../CollectionImage';
// import Overlay from '../../../components/Overlay';
// import collectionZero from '@blank/assets/images/collectionZero.png';

export const ContentWithImage = ({
  collection,
  children,
  imagePropsFront,
  imagePropsBack,
}) => {
  return (
    <>
      <CollectionImage
        collection={collection}
        id="hovered"
        width="100%"
        position="absolute"
        top="0px"
        left="0px"
        // opacity={0.25}
        transition="top 1s, right 1s"
        borderRadius="5px"
        {...imagePropsBack}
        isBack
      />
      <CollectionImage
        collection={collection}
        width="100%"
        position="relative"
        left="-12px"
        top="-6px"
        borderRadius="5px"
        {...imagePropsFront}
      />
      <Overlay display="flex" alignItems="center" justifyContent="center">
        {children}
      </Overlay>
    </>
  );
};

export const ContentWithColor = ({ collection, file, borderRadius = 14 }) => {
  const background =
    collection?.background && collection?.background !== 'undefined'
      ? collection?.background
      : '#E6E6E6';
  const src = useMemo(() => (file ? URL.createObjectURL(file) : file), [file]);
  let image =
    collection?.image_small ||
    collection?.image_preview ||
    collection?.image ||
    src;
  // if (!collection?.name) image = collectionZero;
  const backgroundStyle = !!image
    ? {
        background: `url(${image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }
    : {
        bg: background,
      };

  return (
    <Box
      position="absolute"
      top="0px"
      left="0px"
      width="100%"
      height="100%"
      boxShadow="0px 8px 20px -3px rgba(0, 0, 0, 0.15)"
      borderRadius={borderRadius}
      {...backgroundStyle}
    />
  );
};

const CollectionItemContent = ({
  collection,
  wrapProps = {},
  noImage,
  children,
  width = 250,
  height = 250,
  borderRadius = '20px',
  assetCount,
  collectorCount,
  type,
  showStatistic,
  onClick = () => null,
}) => {
  const { creator, totalParticipants, collectionKey } = collection;
  const collectionType = {
    1: 'Solo',
    2: 'Collab',
    3: 'Group',
    4: 'Solo',
    5: 'Solo',
    6: 'Collab',
    7: 'Group',
    8: 'Open Edition',
  };
  return (
    <Box {...wrapProps} onClick={() => onClick(collection)}>
      <Box position="relative" mb={12} width={width} height={height}>
        {noImage ? (
          <ContentWithColor
            collection={collection}
            borderRadius={borderRadius}
          />
        ) : (
          <ContentWithImage collection={collection} borderRadius={borderRadius}>
            {children}
          </ContentWithImage>
        )}
      </Box>
      <Box
        fontFamily="normal_bold"
        fontSize={16}
        mt={20}
        mb={14}
        textAlign="left"
      >
        <Box
          mb="10px"
          color={
            collectionKey === VOLATILITY_COLECTION_V1_ADDRESS ? '#FFD700' : ''
          }
          css={`
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          `}
        >
          {collection.name || truncateAddress(collection.collectionKey)}
        </Box>
        {type !== 0 && showStatistic && (
          <Box mb="10px" fontWeight={500} fontSize={14} color="#757575">
            {type !== 8 && `${collectionType[type]} | `} NFTs (
            {+assetCount || 0}) | Collectors ({+collectorCount || 0})
          </Box>
        )}
        {type === 0 && showStatistic && (
          <Box mb="10px" fontWeight={500} fontSize={14} color="#757575">
            {`NFTs (`}
            {+assetCount || 0}) | Collectors ({+collectorCount || 0})
          </Box>
        )}
        {creator && (
          <Box display="inline-flex" whiteSpace="nowrap">
            <Text mr="5px">by</Text>
            <Link
              to={WHITE_WALL_ROUTE.replace(':account', creator.address).replace(
                ':tab',
                'profile'
              )}
              maxWidth={100}
              overflow="hidden"
              textOverflow="ellipsis"
              mr="5px"
            >
              @{creator.username || truncateAddress(creator.address)}
            </Link>
            {`and ${totalParticipants - 1} ${
              totalParticipants - 1 > 1 ? 'others' : 'other'
            }`}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const CollectionItem = ({
  noLink,
  type,
  collection,
  featured,
  user,
  check,
  borderRadius,
  filterTab,
  showStatistic = true,
  ...props
}) => {
  // const match = useRouteMatch();
  if (noLink) {
    return <CollectionItemContent collection={collection} {...props} />;
  }

  // const { collectionType = 'collab' } = props;
  // const query = user ? `?user=${user}&check=${check}` : '';
  sessionStorage.setItem('userparam', user || '');
  sessionStorage.setItem('checkparam', check || '');
  let linkPath = `/${collection.collectionKey}`;
  const isVolatility = [
    VOLATILITY_COLECTION_V1_ADDRESS,
    VOLATILITY_COLECTION_V2_ADDRESS,
  ].includes(collection.collectionKey);
  if (isVolatility) linkPath = '/volatility';

  const isCassandra = addressEquals(
    collection.collectionKey,
    CASSANDRA_ADDRESS
  );
  if (isCassandra) linkPath = '/cassandra';

  const isMarcoCollab = addressEquals(
    collection.collectionKey,
    MARCO_COLLAB_ADDRESS
  );
  if (isMarcoCollab) linkPath = '/pdc';

  const isszabotage = addressEquals(
    collection.collectionKey,
    SZABOTAGE_ADDRESS
  );
  if (isszabotage) linkPath = '/szabotage';

  const isTheSmokers = addressEquals(collection.collectionKey, SMOKERS_ADDRESS);
  if (isTheSmokers) linkPath = '/thesmokers';

  const isSandbox = addressEquals(collection.collectionKey, SANDBOX_ADDRESS);
  if (isSandbox) linkPath = '/sandbox';

  return (
    <Link to={linkPath}>
      <CollectionItemContent
        collection={collection}
        type={type}
        borderRadius={borderRadius}
        showStatistic={showStatistic}
        {...props}
      />
    </Link>
  );
};

export default CollectionItem;
