import React, { useState } from 'react';
import Modal from '@blank/components/Modal';
import { Flex, Box, Text, Textarea, Button, Icon } from '@blank/atoms';
import { Form, useFormikContext } from 'formik';
import FormInput from '@blank/components/FormInput';
import ProcessingModal from '@blank/components/ProcessingModal';
import { COLLECTION_TYPES } from '@blank/constants';
import { ContentWithColor } from '../shared/CollectionGrid/CollectionItem';
import FormCustom from '@blank/components/FormCustom';
import FileInput from '@blank/components/FileInput';
import FormFieldWithExtend from '@blank/components/FormFieldWithExtend';
import {
  PRIMARY_COMMUNAL_ROYALTIES,
  SECONDARY_COMMUNAL_ROYALTIES,
  PRIMARY_GA_COMMISSION,
  SECONDARY_GA_COMMISSION,
} from '@blank/constants/message';
import Popover from '@blank/components/Popover';

const TitleText = ({ title }) => (
  <Text fontSize="40px" lineHeight="39px" mb="30px" width="100%">
    {title}
  </Text>
);

const CollectionInfo = ({ title }) => {
  const { values, errors, setFieldValue } = useFormikContext();
  const { type, file } = values;
  const [pop, setPop] = useState(false);
  const [popoverContent, setPopoverContent] = useState();
  const [popoverPosition, setPopoverPosition] = useState({ x: 0, y: 0 });
  const setPopover = (e, pop, content) => {
    if (e) {
      const position = {
        x: e.clientX + 30,
        y: e.clientY - 20,
      };
      setPopoverPosition(position);
    }
    setPop(pop);
    setPopoverContent(content);
  };

  return (
    <>
      <Popover
        open={pop}
        fontFamily="normal"
        fontSize="12px"
        borderRadius="8px"
        padding="20px"
        top={popoverPosition.y}
        left={popoverPosition.x}
        lineHeight="19px"
      >
        {popoverContent}
      </Popover>
      <TitleText title={title} />
      <Flex mb={20} alignItems="center">
        <Flex position="relative" width={100} height={100}>
          <ContentWithColor
            file={file}
            collection={{ background: values.background }}
          />
        </Flex>
        <Flex
          fontWeight={400}
          fontSize={14}
          lineHeight="160%"
          color="primaryLight"
          flexDirection="column"
          pl="16px"
        >
          <Flex>
            <FormCustom
              name="file"
              btnProps={{
                color: 'text',
                textDecoration: 'underline',
                mr: '5px',
                fontFamily: 'normal',
              }}
              wrapperProps={{
                mb: '0',
              }}
              Component={FileInput}
            >
              Upload Cover
            </FormCustom>{' '}
            <Text color="textGray">or use a </Text>
            <Text
              color="text"
              ml="5px"
              textDecoration="underline"
              fontWeight="normal"
              cursor="pointer"
              onClick={() => setFieldValue('file', null)}
            >
              Default Color
            </Text>
          </Flex>
          {values.file && errors?.file && (
            <Text fontSize="1rem" color="red" width="100%" textAlign="center">
              {errors?.file}
            </Text>
          )}
        </Flex>
      </Flex>
      <FormInput
        name="name"
        label="Title"
        placeholder="Title (eg: Untitled) *"
        width="100%"
        wrapperProps={{
          width: '100%',
        }}
        labelProps={{
          mb: '0',
          fontWeight: 400,
          fontSize: '14px',
          color: 'textGray',
        }}
        fontSize="20px"
        variant="line"
      />
      <FormInput
        Component={Textarea}
        name="description"
        label="Description"
        placeholder="Enter description"
        width="100%"
        lineHeight="120%"
        wrapperProps={{
          width: '100%',
        }}
        labelProps={{
          mb: '0',
          fontWeight: 400,
          fontSize: '14px',
          color: 'textGray',
        }}
        fontSize="14px"
        variant="line"
        rows="1"
      />
      <FormInput
        name="symbol"
        label="Symbol"
        placeholder="eg: Blank"
        width="100%"
        textAlign="left"
        wrapperProps={{
          width: '100%',
        }}
        labelProps={{
          mb: '0',
          fontWeight: 400,
          fontSize: '14px',
          color: 'textGray',
        }}
        fontSize="14px"
        variant="line"
      />
      {type === COLLECTION_TYPES.collabs.type && (
        <Box width="100%">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            flexDirection="row"
            mb={25}
          >
            <Flex justifyContent="flex-start" alignItems="center" width="62%">
              <Text
                textAlign="left"
                fontWeight="400"
                fontSize={20}
                lineHeight="100%"
                alignItems="baseline"
              >
                Primary Team Royalties
              </Text>
              <Icon
                ml="10px"
                name={
                  popoverContent === PRIMARY_COMMUNAL_ROYALTIES
                    ? 'info'
                    : 'info-black'
                }
                size="16px"
                cursor="pointer"
                onMouseEnter={(e) =>
                  setPopover(e, true, PRIMARY_COMMUNAL_ROYALTIES)
                }
                onMouseLeave={() => setPopover(null, false, null)}
              />
            </Flex>
            <FormFieldWithExtend
              name="primaryRoyalties"
              width="100%"
              extendText="%"
              isBefore={false}
              extendProps={{
                top: 12,
              }}
              wrapperProps={{
                width: 125,
              }}
              wrapperFieldProps={{
                mb: '0px',
              }}
              pr={40}
              placeholder="10"
              variant="line"
              borderRadius="0px"
            />
          </Flex>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            flexDirection="row"
            mb={30}
          >
            <Flex justifyContent="flex-start" alignItems="center" width="62%">
              <Text
                textAlign="left"
                fontWeight="400"
                fontSize={20}
                lineHeight="100%"
                alignItems="baseline"
              >
                Secondary Team Royalties
              </Text>
              <Icon
                ml="10px"
                name={
                  popoverContent === SECONDARY_COMMUNAL_ROYALTIES
                    ? 'info'
                    : 'info-black'
                }
                size="16px"
                cursor="pointer"
                onMouseEnter={(e) =>
                  setPopover(e, true, SECONDARY_COMMUNAL_ROYALTIES)
                }
                onMouseLeave={() => setPopover(null, false, null)}
              />
            </Flex>
            <FormFieldWithExtend
              name="secondaryRoyalties"
              width="100%"
              extendText="%"
              isBefore={false}
              extendProps={{
                top: 12,
              }}
              wrapperProps={{
                width: 125,
              }}
              wrapperFieldProps={{
                mb: '0px',
              }}
              pr={40}
              placeholder="10"
              variant="line"
              borderRadius="0px"
            />
          </Flex>
        </Box>
      )}
      {type === COLLECTION_TYPES.groups.type && (
        <Box width="100%">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            flexDirection="row"
            mb={25}
          >
            <Flex justifyContent="flex-start" alignItems="center">
              <Text
                textAlign="left"
                fontWeight="400"
                fontSize={18}
                lineHeight="100%"
                alignItems="baseline"
              >
                Primary Group-Admin Commission
              </Text>
              <Icon
                name={
                  popoverContent === PRIMARY_GA_COMMISSION
                    ? 'info'
                    : 'info-black'
                }
                size="16px"
                ml="10px"
                cursor="pointer"
                onMouseEnter={(e) => setPopover(e, true, PRIMARY_GA_COMMISSION)}
                onMouseLeave={() => setPopover(null, false, null)}
              />
            </Flex>
            <FormFieldWithExtend
              name="primaryCommission"
              width="100%"
              extendText="%"
              isBefore={false}
              extendProps={{
                top: 12,
              }}
              wrapperProps={{
                width: 110,
                ml: '40px',
              }}
              wrapperFieldProps={{
                mb: '0px',
              }}
              pr={40}
              placeholder="10"
              variant="line"
              borderRadius="0px"
            />
          </Flex>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            flexDirection="row"
            mb={50}
          >
            <Flex justifyContent="flex-start" alignItems="center">
              <Text
                textAlign="left"
                fontWeight="400"
                fontSize={18}
                lineHeight="100%"
                alignItems="baseline"
              >
                Secondary Group-Admin Commission
              </Text>
              <Icon
                name={
                  popoverContent === SECONDARY_GA_COMMISSION
                    ? 'info'
                    : 'info-black'
                }
                ml="10px"
                size="16px"
                cursor="pointer"
                onMouseEnter={(e) =>
                  setPopover(e, true, SECONDARY_GA_COMMISSION)
                }
                onMouseLeave={() => setPopover(null, false, null)}
              />
            </Flex>
            <FormFieldWithExtend
              name="secondaryCommission"
              width="100%"
              extendText="%"
              isBefore={false}
              extendProps={{
                top: 12,
              }}
              wrapperProps={{
                width: 110,
                ml: '15px',
              }}
              wrapperFieldProps={{
                mb: '0px',
              }}
              pr={40}
              placeholder="10"
              borderRadius="8px"
              variant="line"
            />
          </Flex>
        </Box>
      )}
    </>
  );
};

const CollectionFormModal = ({ inProcess, submitDisabled, onClose }) => {
  const { values, isValid, dirty, submitForm } = useFormikContext();
  const { type } = values;
  const collectionsType = COLLECTION_TYPES[type];
  const title = `Create ${collectionsType?.title} Collection`;

  return inProcess ? (
    <ProcessingModal open>{title}</ProcessingModal>
  ) : (
    <Modal
      open
      onClickOutside={() => onClose()}
      width={600}
      p="32px"
      withCloseIcon
    >
      <Flex as={Form} width="100%" flexDirection="column">
        <CollectionInfo title={title} />
        <Button
          variant="black"
          width="100%"
          height="48px"
          borderRadius="8px"
          disabled={submitDisabled || !isValid || !dirty}
          onClick={() => submitForm()}
          type="button"
        >
          create
        </Button>
      </Flex>
    </Modal>
  );
};

export default CollectionFormModal;
