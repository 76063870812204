import { useCallback, useState, useEffect } from 'react';

import { useContractInfo, useAccount, useConnectWallet } from '.';
import { TRANSACTION_STATUS } from '@blank/common/constants';
import { useAddTransactionToList } from '@blank/common/TransactionContext';
import { useWeb3 } from '@blank/common/WalletContext/hooks/useWeb3';
import { useGlobalState } from '@blank/globalStateContext';

const useMethodSend = (contractName, methodName, reloadInfo) => {
  const [stateGlobal, dispatchGlobal] = useGlobalState();
  const [receipt, setReceipt] = useState();
  const [hash, setHash] = useState();
  const [isSigning, setSigningState] = useState(false);
  const [status, setStatus] = useState();
  const contract = useContractInfo(contractName);
  const address = useAccount();
  const connectWallet = useConnectWallet();
  const addTransactionToList = useAddTransactionToList();
  const web3 = useWeb3();
  if (web3?.eth) {
    web3.eth.transactionBlockTimeout = 1000;
  }

  useEffect(() => {
    if (
      stateGlobal?.transactionStatus === TRANSACTION_STATUS.success &&
      !!stateGlobal.receiptData
    ) {
      setStatus(TRANSACTION_STATUS.success);
      setReceipt(stateGlobal.receiptData);
      dispatchGlobal({
        ...stateGlobal,
        transactionStatus: '',
        receiptData: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateGlobal]);

  const triggerSend = useCallback(
    (args, opts) => {
      if (!address) {
        connectWallet();
        return;
      }
      if (!contract) {
        console.warn(`[SendMethod] Waiting to init contract ${contractName}`);
        return;
      }
      const sendMethod = contract.methods[methodName];
      if (!sendMethod) {
        console.error(
          `[SendMethod] No method ${methodName} in contract ${contractName} found`
        );
        return;
      }
      setSigningState(true);
      setStatus();
      setReceipt();
      setHash();
      sendMethod(...args)
        .estimateGas(opts)
        .then((gasAmount) => {
          try {
            const newOpts = { ...opts, gas: gasAmount };
            sendMethod(...args)
              .send(newOpts)
              .on('transactionHash', (hash) => {
                setHash(hash);
                addTransactionToList({
                  hash: hash,
                  status: TRANSACTION_STATUS.pending,
                  ...(reloadInfo && { reloadInfo }),
                });
                setSigningState(false);
                setStatus(TRANSACTION_STATUS.pending);
              })
              .on('receipt', (receipt) => {
                setTimeout(() => {
                  setReceipt(receipt);
                  setStatus(TRANSACTION_STATUS.success);
                  // updateTransactionToList({
                  //   hash,
                  //   status: TRANSACTION_STATUS.success,
                  //   receipt,
                  // });
                }, 2000);
              })
              // .on('confirmation', (confirmationNumber, receipt) => {
              //   console.log('confirmation receipt', receipt);
              //   console.log('confirmationNumber', confirmationNumber);
              //   setStatus(TRANSACTION_STATUS.success);
              //   setReceipt({ confirmationNumber, receipt });
              // })
              .on('error', () => {
                setSigningState(false);
                setStatus(TRANSACTION_STATUS.error);
              });
          } catch (error) {
            console.error('[ERROR] sendMethod: ', error);
          }
        })
        .catch((err) => {
          try {
            sendMethod(...args)
              .send(opts)
              .on('transactionHash', (hash) => {
                setHash(hash);
                addTransactionToList({
                  hash: hash,
                  status: TRANSACTION_STATUS.pending,
                  ...(reloadInfo && { reloadInfo }),
                });
                setSigningState(false);
                setStatus(TRANSACTION_STATUS.pending);
              })
              .on('receipt', (receipt) => {
                setTimeout(() => {
                  setReceipt(receipt);
                  setStatus(TRANSACTION_STATUS.success);
                  // updateTransactionToList({
                  //   hash,
                  //   status: TRANSACTION_STATUS.success,
                  //   receipt,
                  // });
                }, 5000);
              })
              // .on('confirmation', (confirmationNumber, receipt) => {
              //   console.log('confirmation receipt', receipt);
              //   console.log('confirmationNumber', confirmationNumber);
              //   setStatus(TRANSACTION_STATUS.success);
              //   setReceipt({ confirmationNumber, receipt });
              // })
              .on('error', () => {
                setSigningState(false);
                setStatus(TRANSACTION_STATUS.error);
              });
          } catch (error) {
            console.error('[ERROR] sendMethod: ', error);
          }
        });
    },
    [
      address,
      contract,
      methodName,
      connectWallet,
      contractName,
      addTransactionToList,
      reloadInfo,
    ]
  );

  return [triggerSend, { status, receipt, hash }, isSigning];
};

export default useMethodSend;
