import React, { useState } from 'react';

import { Box, Button, Flex, Text } from '@blank/atoms';
import Modal from '@blank/components/Modal';
import { COLLECTION_TYPES } from '@blank/constants';
import { useUserInfo } from '@blank/pages/shared/ProfileContext';
import SoloCollectionIcon from '@blank/assets/icons/solo-collection.svg';
import CollabCollectionIcon from '@blank/assets/icons/collab-collection.svg';

const MethodForm = ({ onClose, onSubmit }) => {
  const [collectionType, setCollectionType] = useState(null);
  const { isGroupAdmin } = useUserInfo();

  return (
    <Modal
      open
      width={isGroupAdmin ? '900px' : '700px'}
      maxWidth="90vw"
      p="32px"
      borderRadius="14px"
      justifyContent="end"
      alignItems="left"
      onClickOutside={onClose}
      withCloseIcon
    >
      <Flex fontSize="40px" id="modal-action-title">
        <Text textAlign="left" fontSize="40px">
          Launch collection
        </Text>
      </Flex>
      <Box width="100%" fontSize="18px" overflow="auto">
        <Box color="textGray" fontSize="14px">
          Choose which type of collection you wish to launch.
        </Box>
        <Flex
          mt="56px"
          mb="40px"
          css={`
            @media (min-width: 300px) and (max-width: 767px) {
              display: block !important;
            }
          `}
        >
          <Box
            p="20px"
            position="relative"
            width={isGroupAdmin ? '33%' : '49%'}
            minHeight="360px"
            cursor="pointer"
            mr="20px"
            borderRadius="8px"
            background={
              collectionType === COLLECTION_TYPES.creations.type
                ? 'rgba(0, 163, 255, 0.06);'
                : '#fff'
            }
            border="1px solid #0000001f"
            css={`
              @media (min-width: 300px) and (max-width: 767px) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 20px;
              }
            `}
            onClick={() => setCollectionType(COLLECTION_TYPES.creations.type)}
          >
            <Box>
              <img src={SoloCollectionIcon} alt="solo" height={32} />
            </Box>
            <Box fontWeight="400" mb="16px" fontSize="20px">
              Solo collection
            </Box>
            <Box fontSize="14px" position="absolute" bottom="16px">
              Allows only you to mint NFT in.
            </Box>
          </Box>
          <Box
            p="20px"
            width={isGroupAdmin ? '33%' : '49%'}
            mr={isGroupAdmin ? '20px' : ''}
            minHeight="360px"
            position="relative"
            lineHeight="26px"
            cursor="pointer"
            borderRadius="8px"
            background={
              collectionType === COLLECTION_TYPES.collabs.type
                ? 'rgba(0, 163, 255, 0.06);'
                : '#fff'
            }
            border="1px solid #0000001f"
            css={`
              @media (min-width: 300px) and (max-width: 767px) {
                width: 100%;
              }
            `}
            onClick={() => setCollectionType(COLLECTION_TYPES.collabs.type)}
          >
            <img src={CollabCollectionIcon} alt="callab" height={32} />
            <Box fontWeight="400" mb="16px" fontSize="20px">
              Collab collection
            </Box>
            <Box fontSize="14px" position="absolute" bottom="16px" pr="16px">
              Allows you to invite others to mint NFT in & to share the team
              royalties.
            </Box>
          </Box>
          {isGroupAdmin && (
            <Box
              p="20px"
              width="33%"
              minHeight="360px"
              lineHeight="26px"
              cursor="pointer"
              borderRadius="8px"
              position="relative"
              background={
                collectionType === COLLECTION_TYPES.groups.type
                  ? 'rgba(0, 163, 255, 0.06);'
                  : '#fff'
              }
              border="1px solid #0000001f"
              css={`
                @media (min-width: 300px) and (max-width: 767px) {
                  width: 100%;
                }
              `}
              onClick={() => setCollectionType(COLLECTION_TYPES.groups.type)}
            >
              <img src={CollabCollectionIcon} alt="callab" height={32} />
              <Box fontWeight="400" mb="16px" fontSize="20px">
                Group collection
              </Box>
              <Box fontSize="14px" position="absolute" bottom="16px">
                A group collection smart contract
              </Box>
            </Box>
          )}
        </Flex>
        <Box textAlign="center" mt="40px" fontSize="15px">
          <Button
            onClick={() => {
              onClose();
              onSubmit(collectionType);
            }}
            disabled={!collectionType}
            variant="black"
            borderRadius="8px"
            width="100%"
            display="block"
            margin="0 auto"
          >
            continue
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default MethodForm;
