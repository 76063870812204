import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import Overlay from './Overlay';

const drawerRoot = document.getElementById('drawer-root');

const useScrollLock = (lock) => {
  useEffect(() => {
    if (lock) {
      const scrollY = window.scrollY;
      Object.assign(document.body.style, {
        position: 'fixed',
        top: `-${scrollY}px`,
        left: 0,
        right: 0,
      });

      return () => {
        Object.assign(document.body.style, {
          position: '',
          top: '',
          left: '',
          right: '',
        });
        window.scrollTo(0, scrollY);
      };
    }
  }, [lock]);
};

const Drawer = ({
  children,
  open = false,
  onClickOutside,
  overflowY = 'scroll',
}) => {
  useScrollLock(open);
  const onGrayClick = (e) => {
    const grayAreaCoordinate = window.innerWidth - 280;
    if (e.clientX < grayAreaCoordinate) {
      onClickOutside();
    }
  };

  const drawer = open && (
    <Overlay
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="fixed"
      overflowY={overflowY}
      bg="rgba(0,0,0,0.5)"
      zIndex={10}
      onClick={(e) => onGrayClick(e)}
      fontFamily="normal"
      fontSize={4}
      color="text"
      width="100vw"
      css={{
        userSelect: 'none',
      }}
    >
      {children}
    </Overlay>
  );

  return ReactDOM.createPortal(drawer, drawerRoot);
};

export default Drawer;
