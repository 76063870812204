import React from 'react';
import { Text } from '@blank/atoms';
// import useAssetData from '@blank/hooks/useAssetData';
// import { isNil } from 'ramda';
// import { getCollectionParam } from '@blank/utils';
import { useGlobalState } from '@blank/globalStateContext';

const AssetBreadcrumb = ({ collectionKey, id }) => {
  // const { data } = useAssetData(getCollectionParam(collectionKey), id);
  const [state] = useGlobalState();
  const assetName = state?.assetName || id;
  const styleShort = {
    maxWidth: 300,
    overflow: 'hidden',
    display: 'block',
    textOverflow: 'ellipsis',
  };
  return <Text {...styleShort}>{assetName || ''}</Text>;
};

export default AssetBreadcrumb;
