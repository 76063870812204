import React from 'react';

import { Button } from '@blank/atoms';
import { useConnectWallet } from '@blank/common/WalletContext/hooks';

const ConnectButton = ({
  fontSize,
  fontWeight,
  variant,
  color,
  width,
  height,
  textTransform,
  ...props
}) => {
  const connectWallet = useConnectWallet();

  return (
    <Button
      variant={variant ?? 'secondary'}
      fontWeight={fontWeight ?? '400'}
      width={width ?? '140px'}
      height={height ?? '45px'}
      borderRadius="50px"
      textTransform={textTransform}
      onClick={() => connectWallet(true)}
      color="text"
      fontSize="14px"
      letterSpacing="0.01em"
      {...props}
    >
      Connect
    </Button>
  );
};

export default ConnectButton;
