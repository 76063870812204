import { useFormikContext } from 'formik';
import React from 'react';

import { Anchor, Box, Button, Text } from '@blank/atoms';
import { ETHERSCAN_DOMAIN } from '@blank/constants/network';
import Modal from '@blank/components/Modal';
import { ContentWithColor } from '@blank/pages/shared/CollectionGrid/CollectionItem';
import { COLLECTION_TYPES } from '@blank/constants';

const CollectionSuccessModal = ({
  open,
  transactionHash,
  onClose,
  onNext,
  ...props
}) => {
  const { values } = useFormikContext();
  const { type } = values;
  const isCollab = type === COLLECTION_TYPES.collabs.type;

  return (
    <Modal
      open={open}
      alignItems="center"
      p={32}
      width={547}
      height={442}
      {...props}
    >
      <Text
        fontWeight="bold"
        fontSize={28}
        lineHeight="140%"
        color="text"
        textAlign="center"
        mb={48}
      >
        {COLLECTION_TYPES[type].title} Collection Launched!
      </Text>
      <Box position="relative" mb={18} width={100} height={100}>
        <ContentWithColor
          collection={{ background: values.background }}
          file={values.file}
        />
      </Box>
      <Text fontSize={16} lineHeight="170%" color="text">
        <b>{values.name}</b> is successfully created.
      </Text>
      <Anchor
        original
        href={`${ETHERSCAN_DOMAIN}/${transactionHash}`}
        target="_blank"
        textDecoration="underline"
        fontSize={16}
        lineHeight="170%"
        color="textLight"
        mb={36}
      >
        View tx
      </Anchor>
      <Button
        variant="link"
        fontWeight="bold"
        fontSize={16}
        lineHeight="170%"
        onClick={isCollab ? onNext : onClose}
      >
        {isCollab ? 'Next' : 'OK'}
      </Button>
    </Modal>
  );
};

export default CollectionSuccessModal;
