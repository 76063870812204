import theme from '@blank/theme';
import { useField } from 'formik';
import React from 'react';

import { Box } from '../atoms';

const InputWrapper = ({
  name,
  label,
  labelProps,
  children,
  maxWidth,
  ...props
}) => {
  const [, meta] = useField(name);

  return (
    <Box
      mb={24}
      maxWidth={maxWidth}
      {...props}
      borderColor={
        props.metaInLabel && meta.touched && meta.error ? theme.colors.red : ''
      }
    >
      {label && (
        <Box
          color={
            props.metaInLabel && meta.touched && meta.error
              ? theme.colors.red
              : labelProps.labelColor
          }
          mb={12}
          fontWeight="bold"
          {...labelProps}
        >
          {props.metaInLabel && meta.touched && meta.error ? meta.error : label}
        </Box>
      )}
      {children}
      {!props.metaInLabel && meta.touched && meta.error && (
        <Box color="red" fontSize="1rem" mt={1}>
          {meta.error}
        </Box>
      )}
    </Box>
  );
};

export default InputWrapper;
