import React, { createContext, useReducer, useContext } from 'react';

const defaultGlobalState = {};
const GlobalStateContext = createContext(defaultGlobalState);
const DispatchStateContext = createContext(undefined);

const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    (state, newValue) => ({ ...state, ...newValue }),
    defaultGlobalState
  );
  return (
    <GlobalStateContext.Provider value={state}>
      <DispatchStateContext.Provider value={dispatch}>
        {children}
      </DispatchStateContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => [
  useContext(GlobalStateContext),
  useContext(DispatchStateContext),
];

export default GlobalStateProvider;
