export const mobileButtonStyle = {
  css: `
    @media (min-width: 320px) and (max-width: 880px) {
      background: #00A3FF;
      color: #FFFFFF;
    }
  `,
};

export const hideOnMobile = {
  css: `
    @media (min-width: 320px) and (max-width: 767px) {
      display: none;
    }
  `,
};

export const hideOnDesktop = {
  css: `
    @media (min-width: 768px) {
      display: none;
    }
  `,
};

export const mobileUserName = {
  css: `
    @media (min-width: 320px) and (max-width: 767px) {
      max-width: 120px;
    }
  `,
};

export const navStyle = {
  width: '100%',
  css: `
    @media (min-width: 320px) and (max-width: 1231px) {
      width: 87vw;
    }
  `,
};
