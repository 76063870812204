import { Route, Switch } from 'react-router-dom';
import React, { Suspense } from 'react';
import { useAccount } from '@blank/common/WalletContext/hooks';
import { TransactionProvider } from '@blank/common/TransactionContext';

import { JwtProvider } from './shared/JwtContext';
import { ProfileProvider } from './shared/ProfileContext';
import Layout from './shared/Layout';
import LoadingContainer from './LoadingContainer';
import Spinner from '@blank/components/Spinner';
// import routeConfigs from './routerConfigs';
import PageNotFound from './shared/PageNotFound';
import { LANDING_ROUTE } from './routeConstants';
import { lazy } from 'react';

const LANDING_COMPONENT = lazy(() => import('./Home'));

const Routes = ({ account }) => {
  return (
    <Layout dapp={!!account}>
      <Switch>
        {/* {routeConfigs.map((item) => (
          <Route key={item.path} exact={item.exact} path={item.path} strict>
            <Suspense fallback={<Spinner m="auto" mt={90} />}>
              <item.component />
            </Suspense>
          </Route>
        ))} */}
        <Route key={LANDING_ROUTE} exact={true} path={LANDING_ROUTE} strict>
          <Suspense fallback={<Spinner m="auto" mt={90} />}>
            <LANDING_COMPONENT />
          </Suspense>
        </Route>
        <Route component={PageNotFound} />
      </Switch>
    </Layout>
  );
};

const Dapp = () => {
  const account = useAccount();

  return (
    <LoadingContainer>
      {!account ? (
        <Routes />
      ) : (
        <JwtProvider>
          <ProfileProvider>
            <TransactionProvider>
              <Routes account={account} />
            </TransactionProvider>
          </ProfileProvider>
        </JwtProvider>
      )}
    </LoadingContainer>
  );
};

export default Dapp;
