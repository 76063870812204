import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { Box, Icon } from '../atoms';
import Overlay from './Overlay';
import Spinner from './Spinner';

const modalRoot = document.getElementById('modal-root');

const mobileStyle = {
  css: `
    @media (min-width: 320px) and (max-width: 767px) {
      max-width: 90vw;
      max-height: 90vh;
      overflow-y: auto;
    }
  `,
};

const LoadingOverlay = () => (
  <Overlay
    bg="rgba(255,255,255,0.8)"
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <Spinner />
  </Overlay>
);

const useScrollLock = (lock) => {
  useEffect(() => {
    if (lock) {
      const scrollY = window.scrollY;
      Object.assign(document.body.style, {
        position: 'fixed',
        top: `-${scrollY}px`,
        left: 0,
        right: 0,
      });

      return () => {
        Object.assign(document.body.style, {
          position: '',
          top: '',
          left: '',
          right: '',
        });
        window.scrollTo(0, scrollY);
      };
    }
  }, [lock]);
};

const Modal = ({
  children,
  open = false,
  loading = false,
  onClickOutside,
  withCloseIcon = false,
  maxWidth,
  overflowY = 'scroll',
  pt,
  ...props
}) => {
  useScrollLock(open);
  const ref = useRef();
  const [paddingTop, setPaddingTop] = useState(0);
  const [paddingBottom, setPaddingBottom] = useState(0);

  useEffect(() => {
    if (ref?.current?.clientHeight) {
      const diff = ref?.current?.clientHeight - window.innerHeight;
      setPaddingBottom(diff / 2);
      setPaddingTop(diff * 2);
    }
  }, [open]);

  const modal = open && (
    <Overlay
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="fixed"
      overflowY={overflowY}
      pt={pt || paddingTop}
      pb={paddingBottom}
      bg="rgba(0,0,0,0.5)"
      zIndex={10}
      onClick={onClickOutside}
      fontFamily="normal"
      fontSize={4}
      color="text"
      width="100%"
      css={{
        userSelect: 'none',
      }}
    >
      <Box
        bg="background"
        position="relative"
        overflow="hidden"
        borderRadius={20}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        ref={ref}
        {...props}
        {...(children.props?.bg ? {} : mobileStyle)}
        maxWidth={maxWidth}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {withCloseIcon && (
          <Box width="100%">
            <Icon
              name="exit-nav-thin"
              size="30px"
              onClick={onClickOutside}
              cursor="pointer"
              position="absolute"
              top="25px"
              right="25px"
            />
          </Box>
        )}
        {children}
        {loading && <LoadingOverlay />}
      </Box>
    </Overlay>
  );

  return ReactDOM.createPortal(modal, modalRoot);
};

export default Modal;
