import React from 'react';

const reducer = (_, width) => {
  const newState = {};
  newState.mobile = width <= 600;
  newState.tablet = width > 601 && width <= 768;
  newState.bigTablet = width >= 769 && width <= 1024;
  newState.desktop = width >= 1200 && width <= 1323;
  newState.bigDesktop = width > 1323;

  return newState;
};

const initialState = {
  mobile: false,
  tablet: false,
  bigTablet: false,
  desktop: false,
  bigDesktop: false,
};

export default () => {
  const [node, ref] = React.useState(null);

  const [matches, dispatch] = React.useReducer(reducer, initialState);

  const observerRef = React.useRef(null);

  if (!observerRef.current) {
    observerRef.current = new ResizeObserver(([entry]) => {
      dispatch(entry.borderBoxSize?.inlineSize ?? entry.contentRect.width);
    });
  }

  React.useEffect(() => {
    const { current: observer } = observerRef;

    if (node) {
      dispatch(node.getBoundingClientRect().width);
      observer.observe(node);
    }

    return () => {
      observer.disconnect();
    };
  }, [node]);

  return [matches, ref];
};
