// import BlankCollabCollection from '../contracts/BlankCollabCollection.json';
import BlankCollabCollectionV2 from '../contracts/BlankCollabCollectionV2.json';
// import BlankGroupCollection from '../contracts/BlankGroupCollection.json';
import BlankGroupCollectionV2 from '../contracts/BlankGroupCollectionV2.json';
// import BlankSoloCollection from '../contracts/BlankSoloCollection.json';
import BlankSoloCollectionV2 from '../contracts/BlankSoloCollectionV2.json';
import BlankVolatilityArtCollection from '../contracts/BlankVolatilityArtCollection.json';

export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000';
export const OBJECT_ATTR_COLLECTION = [
  '0xb55c5cac5014c662fdbf21a2c59cd45403c482fd',
];

export const COLLECTION_COLORS = [
  '#FF4499',
  '#FF4F5A',
  '#B55FFF',
  '#B55FFF',
  '#44F0E5',
  '#C2FE3A',
  '#FFB939',
];

export const COLLECTION_TYPE_TO_ABI = {
  0: null,
  1: BlankSoloCollectionV2,
  2: BlankCollabCollectionV2,
  3: BlankGroupCollectionV2,
  4: BlankVolatilityArtCollection,
  5: BlankSoloCollectionV2,
  6: BlankCollabCollectionV2,
  7: BlankGroupCollectionV2,
};

export const COLLECTION_TYPE_KEY = {
  0: 'collection',
  1: 'creation',
  2: 'collab',
  3: 'collection',
};

export const COLLECTION_TYPES = {
  creations: {
    type: 'creations',
    title: 'Solo',
    typeKey: 'creation',
    key: 1,
  },
  collabs: {
    type: 'collabs',
    title: 'Collab',
    typeKey: 'collab',
    key: 2,
  },
  groups: {
    type: 'groups',
    title: 'Group',
    typeKey: 'group',
    key: 3,
  },
  creationsV2: {
    type: 'creations',
    title: 'Solo',
    typeKey: 'creation',
    key: 5,
  },
  collabsV2: {
    type: 'collabs',
    title: 'Collab',
    typeKey: 'collab',
    key: 6,
  },
  groupsV2: {
    type: 'groups',
    title: 'Group',
    typeKey: 'group',
    key: 7,
  },
  volatilityArt: {
    type: 'volatilityArt',
    title: 'Volatility Art',
    // typeKey: 'group',
    // key: 3,
  },
  collections: {
    type: 'collections',
    title: 'Collection',
    typeKey: 'collection',
  },
  featured: {
    type: 'featured',
    title: 'Featured Collection',
    typeKey: 'featured',
  },
};

export const MEMBER_TYPE = {
  founder: 1,
  member: 2,
};

export const NOTIFICATION_TYPES = {
  // RECEIVED_INVITATION_COLLAB: 1, // there is new invitation to join collab from other user
  // RECEIVED_INVITATION_GROUP: 100,
  // ANOTHER_REJECT_INVITATION: 2, // another user rejected your invatation
  // ACCEPT_INVITATION: 200,
  // RECEIVED_ASSET: 3, // another user transfer asset
  ASSET_IS_TRANSFERRED: 3,
  BID_IS_OPENED: 6,
  BID_IS_CANCELED: 7,
  BID_IS_ACCEPTED: 8,
  SALE_ORDER_IS_BOUGHT: 9,
  INVITATION_IS_CREATED: 11,
  INVITATION_IS_DECLINED: 12,
  INVITATION_IS_ACCEPTED: 13,
  AUCTION_OFFER: 18,
  WON_AUCTION: 16,
};

export const IPFS_TYPES = {
  asset: 'asset',
};

export const WHITE_WALL_TABS_NAME = {
  profile: 'profile',
  // activity: 'activity',
  activity: 'profile',
  explore: 'explore',
  studio: 'studio',
  mynft: 'mynft',
  in_wallet: 'in_wallet',
  created: 'created',
  owned: 'owned',
  collabs: 'collabs',
  collections: 'collections',
};

export const BYTE_PER_MB = 1000000;
export const MINT_MAX_FILE_SIZE = 600;
export const MINT_MAX_JS_FILE_SIZE = 10;
export const MINT_MAX_THUMBNAIL_SIZE = 30;
export const MINT_FILE_TYPES = [
  'video/mp4',
  'audio/mpeg',
  'image/png',
  'image/jpeg',
  'image/gif',
  'text/plain',
  'text/markdown',
];
export const MINT_FILE_TYPES_SCRIPT = [
  'video/mp4',
  'audio/mpeg',
  'image/png',
  'image/jpeg',
  'image/gif',
  'text/javascript',
  'text/plain',
  'text/markdown',
];
export const TEXT_FILE = ['text/plain', 'text/markdown'];
export const MINT_THUMBNAIL_TYPES = ['image/png', 'image/jpeg', 'image/gif'];
export const VIDEO_TYPES = ['video/mp4'];
export const AUDIO_TYPES = ['audio/mpeg'];
export const JS_TYPE = ['text/javascript'];
export const MINT_FILE_HAVE_THUMBNAL = [
  ...VIDEO_TYPES,
  ...AUDIO_TYPES,
  ...JS_TYPE,
];

export const LOGIN_INFO = {
  username: 'blankdotart',
  password: 'art+smartcontracts',
};

export const COINS = [{ name: 'ETH' }, { name: 'SAND' }];

export const NONE_ADDRESS = '0x0000000000000000000000000000000000000000';

export const MOCK_QUERY = {
  1: 'stock=1&unix_countdown=1628198760&countdown_stage=1',
  2: 'stock=1&unix_countdown=1628198760&countdown_stage=2',
  3: 'stock=1&unix_countdown=1628198760&countdown_stage=3',
  4: 'stock=1&unix_countdown=1628198760&countdown_stage=4',
  5: 'stock=1&unix_countdown=0&countdown_stage=1',
  6: 'stock=0&unix_countdown=0&countdown_stage=1',
};
export const COLLECTION_TYPES_NAME = {
  1: 'Solo',
  2: 'Collab',
  3: 'Group',
  4: 'Solo',
  5: 'Solo',
  6: 'Collab',
  7: 'Group',
};
