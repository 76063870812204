const themeFontSize = {
  xSmall: ['1.2rem', '1.2rem', '1.2rem', '1.2rem'],

  small: ['1.4rem', '1.4rem', '1.4rem', '1.4rem'],

  normal: ['1.6rem', '1.6rem', '1.6rem', '1.6rem'],

  largeText: ['2rem', '2rem', '2rem', '2rem'],

  cardTitle: ['2.4rem', '2.4rem', '2.4rem', '2.4rem'],

  collectionName: ['3.2rem', '3.2rem', '3.2rem', '3.2rem'],

  title: ['3.6rem', '3.6rem', '3.6rem', '3.6rem'],

  small_responsive: ['1.2rem', '1.2rem', '1.2rem', '1.4rem'],

  normal_responsive: ['1.4rem', '1.4rem', '1.4rem', '1.6rem'],

  large_responsive: ['1.6rem', '1.6rem', '1.6rem', '2rem'],

  collectionName_responsive: ['2.4rem', '2.4rem', '2.4rem', '3.2rem'],

  hero_message_responsive: ['6rem', '8rem', '14rem', '16rem'],

  hero_subtitle_responsive: ['1.6rem', '1.6rem', '2rem', '2rem'],

  title_responsive: ['2.4rem', '2.4rem', '3.2rem', '3.6rem'],

  sideNav: ['2.4rem', '2.4rem', '2.4rem', '3.2rem'],

  mainTitle: ['6rem', '8rem', '11rem', '11rem'],
};

export default {
  colors: {
    white: '#fff',
    black: '#000',
    red: '#f00',
    text: '#000',
    textOvelay: 'rgba(0, 0, 0, 0.8)',
    textSecondary: '#818181',
    textSecondaryLight: '#C4C4C4',
    textHeader: '#757575',
    borderLightGrey: '#C4C4C4',
    background: '#fff',
    primary: '#000',
    primaryPurple: '#AB05F2',
    secondary: '#00A3FF',
    secondaryHover: '#39B7FF',
    primaryDisabled: '#E9E9E9',
    primaryTextDisabled: '#C4C4C4',
    textLight: 'rgba(0, 0, 0, 0.6)',
    backgroundLight: 'rgba(255, 255, 255, 0.7)',
    primaryNormalLight: 'rgba(0, 0, 0, 0.5)',
    primaryLight: 'rgba(0, 0, 0, 0.3)',
    primarySemiLight: 'rgba(0, 0, 0, 0.1)',
    primarySuperLight: 'rgba(0, 0, 0, 0.03)',
    tab: 'rgba(0, 0, 0, 0.2)',

    green: '#1DF290',
    completed: '#17C25F',
    textGray: 'rgba(0, 0, 0, 0.4)',
  },

  gradientColors: {
    primary: 'linear-gradient(90deg, #00F5A0 0%, #00D9F5 100%)',
  },

  fonts: {
    normal: 'Poppins',
    normal_semiBold: 'Poppins SemiBold',
    normal_bold: 'Poppins Bold',
    normal_light: 'Poppins Light',
    normal_italic: 'Poppins Italic',
    special: 'Poppins',
    secondary: 'Playfair Display Regular',
    pageTitle: 'PP Formula Condensed',
    Lato: 'Lato',
  },

  breakpoints: ['40em', '52em', '90rem'],

  fontSizes: [
    '0.8rem',
    '1rem',
    '1.2rem',
    '1.4rem',
    '1.6rem',
    '1.8rem',
    '2rem',
    '2.4rem',
    '2.8rem',
    '3.2rem',
  ],

  fontSize: themeFontSize,

  space: [
    0,
    '0.4rem',
    '0.8rem',
    '1.6rem',
    '3.2rem',
    '6.4rem',
    '12.8rem',
    '25.6rem',
    '51.2rem',
  ],
  variants: {
    // Button variants
    button: {
      secondary: {
        p: '12.5px 30px',
        fontWeight: '600',
        color: '#000',
        bg: 'background',
        borderRadius: '77px',
        fontSize: 14,
        // boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.12)',
        border: '1px solid #EBEBEB',
        lineHeight: '140%',
        ':disabled': {
          color: 'primaryLight',
          cursor: 'default',
          boxShadow: 'none !important',
        },
        ':hover': {
          bg: '#fff',
          border: '1px solid transparent',
          boxShadow: '0px 4px 25px -3px rgba(0, 0, 0, 0.1)',
        },
      },
      primary: {
        p: '12.5px 30px',
        border: 'none',
        fontWeight: '600',
        color: 'background',
        bg: 'secondary',
        borderRadius: '77px',
        fontSize: 14,
        // boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.12)',
        lineHeight: '140%',
        ':hover': {
          bg: '#008BD9',
          boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.05)',
        },
        ':disabled': {
          color: 'primaryTextDisabled',
          bg: 'primaryDisabled',
          cursor: 'default',
          boxShadow: 'none !important',
        },
      },
      black: {
        p: '12.5px 30px',
        border: 'none',
        fontWeight: '600',
        color: 'background',
        bg: '#000',
        borderRadius: '77px',
        fontSize: 14,
        lineHeight: '140%',
        ':hover': {
          background: '#232323',
          boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.05)',
        },
        ':disabled': {
          color: 'primaryTextDisabled',
          bg: 'primaryDisabled',
          cursor: 'default',
          boxShadow: 'none !important',
        },
      },
      link: {
        color: 'text',
        fontSize: 18,
        lineHeight: '170%',
        bg: 'transparent',
        fontWeight: 'normal',
        textAlign: 'center',
        border: 'none',
        textDecoration: 'underline',
        p: 0,
        ':hover': {
          bg: 'transparent !important',
          boxShadow: 'none !important',
        },
        ':disabled': {
          color: 'primaryTextDisabled',
          bg: 'transparent !important',
          cursor: 'default',
          textDecoration: 'none',
        },
      },
      reverse: {
        color: 'background',
        bg: 'primary',
        border: '1px solid',
        borderColor: 'primary',
        borderRadius: 2,
        p: '0.6rem 1.4rem',
      },
      outline: {
        color: 'primary',
        bg: 'transparent',
        border: '1px solid',
        borderColor: 'primary',
        borderRadius: 2,
        p: '0.6rem 1.4rem',
      },
    },

    // input variants
    input: {
      primary: {
        p: '12px 20px',
        border: '0.5px solid',
        borderColor: 'primaryLight',
        borderRadius: 100,
        color: 'text',
        fontWeight: '600',
        fontSize: 16,
        lineHeight: '100%',
        css: {
          '::placeholder': {
            color: 'primaryLight',
          },
        },
      },
      elegant: {
        py: 2,
        border: 'none',
        borderBottom: '1px solid',
        borderColor: 'textLight',
        ':not(:placeholder-shown)': {
          borderColor: 'text',
        },
        bg: 'transparent',
      },
      line: {
        p: '8px 0px',
        border: 0,
        outline: 0,
        background: 'transparent',
        borderBottom: '0.5px solid',
        borderColor: 'primaryLight',
        color: 'text',
        fontWeight: '600',
        fontSize: 16,
        lineHeight: '100%',
        borderRadius: '0px',
        css: {
          '::placeholder': {
            color: 'primaryLight',
          },
        },
      },
    },

    // Text variants
    text: {
      normal: {
        color: 'text',
        fontFamily: 'normal',
        fontSize: themeFontSize.normal,
      },
      normalSecondary: {
        color: 'textSecondary',
        fontFamily: 'normal',
        fontSize: themeFontSize.normal,
      },
      small: {
        color: 'text',
        fontFamily: 'normal',
        fontSize: themeFontSize.small,
      },
      smallSecondary: {
        color: 'textSecondary',
        fontFamily: 'normal',
        fontSize: themeFontSize.small,
      },
      largeText: {
        color: 'text',
        fontFamily: 'normal',
        fontSize: themeFontSize.large,
      },
      largeTextBold: {
        color: 'text',
        fontFamily: 'normal_bold',
        fontSize: themeFontSize.large,
      },
      collectionName: {
        color: 'text',
        fontFamily: 'secondary',
        fontSize: themeFontSize.collectionName_responsive,
      },
      title: {
        color: 'text',
        fontFamily: 'normal',
        fontSize: themeFontSize.title,
      },
      mainTitle: {
        color: 'text',
        fontFamily: 'pageTitle',
        fontSize: themeFontSize.mainTitle,
      },
    },
  },
};
