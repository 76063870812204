import { getResizedImage } from '@blank/pages/shared/getResizedImage';
export const resizeImg = (
  data,
  size = 320,
  collection = data?.collection_id,
  id = data?.id
) => {
  return getResizedImage(
    size,
    `${collection}_${id}`,
    data?.metadata?.image || ''
  );
};
