export const MAINNET = 'main';
export const RINKEBY = 'rinkeby';

export const IS_NETWORK_RINKEBY = process.env.REACT_APP_NETWORK === RINKEBY;
const ENV = process.env.REACT_APP_ENV;
const APP_DOMAIN = {
  development: 'https://dev.blanknetwork.com',
  staging: 'https://staging.blank.art',
  prod: 'https://www.blank.art',
};

const networkConfig = {
  [MAINNET]: {
    id: 1,
    chainId: 1,
    name: 'Mainnet',
    network: 'mainnet',
    domain: APP_DOMAIN[ENV],
    etherscan: 'https://etherscan.io/tx',
    opensea: 'https://opensea.io/assets',
    factoryAddress: '0xd76f16e38d152cac0cfbe1dc1d844cbfa3e2ab0d',
    cryptoPunkAddress: '0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb', // for bounty
    marketAddress: '0x4662c5fdd44239f0482251a6b81e358cd1ba9549',
    blanknftAddress: '0x01bdba36e0b921a4c69d1c33a76521a2356b7e4d',
    auctionAddress: '0xeC875c249D1e8457C2f90F6798d223F3C59De0cf',
    soloLogicAddress: '0x910054b46a72e0e3d401b0f638aa21c15ed2cc40',
    collabLogicAddress: '0x2388266a3eb3bfe87771cb0cc316e440edc57384',
    groupLogicAddress: '0x1ac750d5eb24cee629a97de83473821e7e215c79',
    volatilityArtLogicAddress: '0x0cc7100ba9f971818ad27dbb35ca92112b8302ea',
    volatilityColectionV1Address: '0xbc6c831a566eb4018b13968c45304cfc3c674df8',
    volatilityColectionV2Address: '0x7896d1c0b0f3fa9e4e941242c84cace1e810cffb',
    soloLogicAddressV2: '0x77e9A87a43F4029005C88b1ED05fab34ef6Df73C',
    collabLogicAddressV2: '0x2b3e7f440ce8289dc7cf030ab409a30458c3a38e',
    groupLogicAddressV2: '0x00Efe8715b45e5aeEc6FBb16396E415Da51346dF',
    openEditionAddress: '0xd49f9c4026ce6fa9a2d95527508406351283ba5c',
    openEditionCollectionAddress: '0x1eaee0725988de18c40b625113f26a38227b9887',
    cassandraAddress: '0x4ae8cf925e010b917a7337796c7b91ee46e4beb5',
    sarahArtLogicAddress: '0xcf0949bf6d2adf8032260fd08039c879cf71c128',
    oficinasAddress: '0xa4ad045d62a493f0ed883b413866448afb13087c',
    mattKaneAddress: '0x5a483c063b6b2b4f0baafbfe799a3504dd477dae',
    marcoAdress: '0x8349dc07bfbe5ff2fd9befc477c87490bbd36b68',
    sarahColectionAddress: '0xbc6c831a566eb......',
    cassandraUniqueNFTLink:
      'https://ipfs.blanknetwork.com/ipfs/QmNuzPHFSAZ2RpQ37p2598u5dhXPfhYmmkseWYrG8dbdt3/eba3ac89cd8ffdd5c968840d5b83a626.gif',
    sarahAvatar:
      'https://ipfs.pixura.io/ipfs/QmS7U2KGvjJAMUwLhRk1QqxGut6NsAUGD2w3drCXTfUrXG',
    sarahOEDescription: '',
    uniqueNFTName: 'The Prophecy Channel',
    marcoCollabAddress: '0xd6bdf525eb77de428daa0cf273d9751df842bfd3',
    smokersAddress: '0x5c98f139c32a1da76e966699c3ec9a6aa9a543b0',
    sandboxAddress: '0x5deb4d9048627c50031b2f46bc69c9e25bc0728c',
    etherscanApi: 'https://api.etherscan.io',
    socketUrl: 'wss://socket.blank.art',
    erc20Address: '0x3845badAde8e6dFF049820680d1F14bD3903a5d0',
    shizhengAddress: '0x9fcdbe2a04c7203983ea3ade0e83b4ae84058dc6',
    imageDomain: 'https://image.blank.art',
    superrareAddress: '0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0',
    szabotageAddress: '0xee40985d4f58f05e0839765810602fba9a06f0c2',
    raribleDomain: 'https://ethereum-api.rarible.org',
  },
  [RINKEBY]: {
    id: 4,
    chainId: 4,
    name: 'Rinkeby',
    network: 'rinkeby',
    domain: APP_DOMAIN[ENV],
    etherscan: 'https://rinkeby.etherscan.io/tx',
    opensea: 'https://testnets.opensea.io/assets',
    cryptoPunkAddress: '0xadf1b777ba5bde039283bb41c5b376c5397ff348', // for bounty
    bountyAddress: '0x0c8a1edf307a38ee57848dc1e0f71a3e5365b59e', // for bounty
    factoryAddress: '0x08e11f02861c5560f5a3c6e881df3ea5e70d48e7',
    marketAddress: '0x38b4a7eb1ddb3ee90b9c43855f003e0609f9cbfc',
    blanknftAddress: '0xea491a46172342617d208d52c21dddb8262afac9',
    auctionAddress: '0xf256135F557Ec329cd0f9a80D419d8be1093c746',
    soloLogicAddress: '0x43930522623df5e90729872ba8e9fb404b5c5487',
    collabLogicAddress: '0x67813fafd27e715783b8542a1428e752c208e85c',
    groupLogicAddress: '0xff76c4921065f97774e71727fba1558f133d6102',
    volatilityArtLogicAddress: '0xeb65b44c8bd0172796de2b217bafe95737bccfac',
    volatilityColectionV1Address: '0x4ab6dcc50c95fae4c656906d52b9c0de534d7196',
    volatilityColectionV2Address: '0xbf9ea1bf1e0ca78be34c2ff8d3711e915d0dc3b1',
    soloLogicAddressV2: '0x355D69071C4224Df0e36C158f74A8C4C6a0495D3',
    collabLogicAddressV2: '0xE427099D4b743E7a949eCbEC35ba8F9E3e330AD6',
    groupLogicAddressV2: '0x497ad64C31E6A8CEE43062d3cf8A31391017f20A',
    openEditionAddress: '0x1486cd9cc6ea2b45185447c395180779316d45da',
    openEditionCollectionAddress: '0x500c5ff833e5f221d4ac76f88a9e654856305ed7',
    sarahArtLogicAddress: '0x91afb61387ef39cb0a6a602895a7e77f2ac36a82',
    mattKaneAddress: '0x5a483c063b6b2b4f0baafbfe799a3504dd477dae',
    oficinasAddress: '0xa4ad045d62a493f0ed883b413866448afb13087c',
    marcoAdress: '0x8349dc07bfbe5ff2fd9befc477c87490bbd36b68',
    sarahColectionAddress: '0x9fc41d79F4D66302FBFE67B121adE59d01C4dD1d',
    cassandraAddress: '0x05094d78e6094c8beeadaf3d97946b9d033d0a8d',
    cassandraUniqueNFTLink:
      'https://ipfs.blanknetwork.com/ipfs/QmNuzPHFSAZ2RpQ37p2598u5dhXPfhYmmkseWYrG8dbdt3/eba3ac89cd8ffdd5c968840d5b83a626.gif',
    sarahAvatar:
      'https://ipfs.pixura.io/ipfs/QmS7U2KGvjJAMUwLhRk1QqxGut6NsAUGD2w3drCXTfUrXG',
    sarahOEDescription: 'Insert here a description for OE',
    uniqueNFTName: 'Unique NFT',
    marcoCollabAddress: '0xe0aa055268b259b79e3975360cec98982a75ba77',
    smokersAddress: '0x5c98f139c32a1da76e966699c3ec9a6aa9a543b0',
    sandboxAddress: '0xef44186d47778cfbb09419fbcd5a83211b88f204',
    etherscanApi: 'https://api-rinkeby.etherscan.io',
    socketUrl: 'wss://socket-dev.blank.art',
    erc20Address: '0xBD0dBcb269D08230f8C5e137700158B136Ec9Dc4',
    shizhengAddress: '0x9fcdbe2a04c7203983ea3ade0e83b4ae84058dc6',
    imageDomain: 'https://image-dev.blank.art',
    superrareAddress: '0x30ac9981855e9d6eba559d3ed0a08806cf8da4ab',
    raribleDomain: 'https://ethereum-api-staging.rarible.org',
  },
};

export const {
  id: NETWORK_ID,
  chainId: CHAIN_ID,
  name: NETWORK_NAME,
  domain: DOMAIN,
  network: NETWORK,
  etherscan: ETHERSCAN_DOMAIN,
  opensea: OPENSEA_DOMAIN,
  factoryAddress: FACTORY_ADDRESS,
  marketAddress: MARKET_ADDRESS,
  blanknftAddress: BLANKNFT_ADDRESS,
  auctionAddress: AUCTION_ADDRESS,
  bountyAddress: BOUNTY_ADDRESS,
  cryptoPunkAddress: CRYPTO_PUNK_ADDRESS,
  soloLogicAddress: SOLO_LOGIC_ADDRESS,
  collabLogicAddress: COLLAB_LOGIC_ADDRESS,
  groupLogicAddress: GROUP_LOGIC_ADDRESS,
  soloLogicAddressV2: SOLO_LOGIC_ADDRESS_V2,
  collabLogicAddressV2: COLLAB_LOGIC_ADDRESS_V2,
  groupLogicAddressV2: GROUP_LOGIC_ADDRESS_V2,
  volatilityArtLogicAddress: VOLATILITY_ART_LOGIC_ADDRESS,
  volatilityColectionV1Address: VOLATILITY_COLECTION_V1_ADDRESS,
  volatilityColectionV2Address: VOLATILITY_COLECTION_V2_ADDRESS,
  openEditionAddress: OPENEDITION_ADDRESS,
  openEditionCollectionAddress: OPENEDITION_COLLECTION_ADDRESS,
  sarahArtLogicAddress: SARA_ART_LOGIC_ADDRESS,
  oficinasAddress: OFICINAS_ADDRESS,
  mattKaneAddress: MATTKANE_ADDRESS,
  marcoAdress: MARCO_ADDRESS,
  sarahColectionAddress: SARA_COLECTION_ADDRESS,
  cassandraAddress: CASSANDRA_ADDRESS,
  cassandraUniqueNFTLink: UNIQUE_LINK,
  sarahAvatar: SARAH_AVATAR,
  sarahOEDescription: OE_DESCRIPTION,
  uniqueNFTName: UNIQUE_NAME,
  marcoCollabAddress: MARCO_COLLAB_ADDRESS,
  smokersAddress: SMOKERS_ADDRESS,
  sandboxAddress: SANDBOX_ADDRESS,
  etherscanApi: ETHERSCAN_API,
  socketUrl: SOKET_URL,
  erc20Address: ERC20_ADDRESS,
  shizhengAddress: SHIZHENG_ADDRESS,
  imageDomain: IMAGE_DOMAIN,
  superrareAddress: SUPPERATE_ADDRESS,
  szabotageAddress: SZABOTAGE_ADDRESS,
  raribleDomain: RARIBLE_DOMAIN,
} = networkConfig[process.env.REACT_APP_NETWORK || MAINNET];
