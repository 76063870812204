import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React from 'react';

// import { WalletContextProvider } from '@blank/common/WalletContext';
import Dapp from './Dapp';

const Pages = () => (
  <Router>
    {/* <WalletContextProvider> */}
    <Switch>
      <Route>
        <Dapp />
      </Route>
    </Switch>
    {/* </WalletContextProvider> */}
  </Router>
);

export default Pages;
