import React from 'react';
import { any, prop } from 'ramda';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';

import { Anchor, Box, Flex, Link } from '../../../../atoms';
import { EXPLORE_TEST_ROUTE } from '@blank/pages/routeConstants';

const NavItem = ({ label, href, external, pages = [], menuKey, ...props }) => {
  const location = useLocation();
  let matched = any((path) =>
    prop('isExact')(matchPath(location.pathname, path))
  )(pages);

  return !href ? (
    <Box {...props} ml={menuKey === 'notifications' ? 0 : props.ml}>
      {label}
    </Box>
  ) : external ? (
    <Anchor href={href} target="blank" {...props}>
      {label}
    </Anchor>
  ) : (
    <Link
      to={href}
      color={matched ? 'primary' : 'textHeader'}
      fontWeight={400}
      fontFamily="normal"
      fontSize="15px"
      {...props}
      css={`
        :hover {
          color: #000;
        }
      `}
    >
      {label}
    </Link>
  );
};
const Navigator = ({ navs, gap = 36, propsnav, ...props }) => {
  const location = useLocation();
  const isTestPage = location.pathname === EXPLORE_TEST_ROUTE;

  return (
    <Flex
      alignItems="center"
      fontWeight="400"
      fontSize={15}
      whiteSpace="nowrap"
      {...props}
      fontFamily={isTestPage ? 'helvetica' : ''}
    >
      {navs.map((nav, index) => (
        <NavItem
          {...nav}
          ml={index > 0 ? gap : 0}
          menuKey={nav.key}
          {...propsnav}
        />
      ))}
    </Flex>
  );
};

export default Navigator;
