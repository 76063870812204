import React from 'react';

import { Anchor, Button, Text, Flex, Box } from '@blank/atoms';
import { ETHERSCAN_DOMAIN } from '@blank/constants/network';
import Modal from '@blank/components/Modal';

const TransactionSubmittedModal = ({
  open,
  transactionHash,
  onClose,
  ...props
}) => {
  return (
    <Modal open={open} p="32px 45px" width={547} height={260} {...props}>
      <Box width="100%">
        <Flex justifyContent="start" fontSize="36px" mb="24px">
          <Text>Transaction submitted</Text>
        </Flex>
        <Text fontWeight="600">
          Your transaction is being processed. {` `}
          <Anchor
            original
            href={`${ETHERSCAN_DOMAIN}/${transactionHash}`}
            target="_blank"
            textDecoration="underline"
            color="text"
          >
            View tx
          </Anchor>
        </Text>
        <Box mt="44px" textAlign="center">
          <Button
            variant="secondary"
            width="205px"
            borderRadius="10px"
            onClick={() => onClose()}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default TransactionSubmittedModal;
