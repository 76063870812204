import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from '@react-hook/media-query';
import { ENABLE_COMING_SOON } from '@blank/constants/flags';
import ConnectButton from '@blank/common/WalletContext/shared/ConnectButton';
import { Image, Flex, Button, Link } from '@blank/atoms';
import logo from '@blank/assets/icons/logo.svg';
import { useAccount } from '@blank/common/WalletContext/hooks';

import {
  HOME_ROUTE,
  EXPLORE_ROUTE,
  LANDING_ROUTE,
  MINT_ROUTE,
  FEATURED_COLLECTIONS_PAGE_ROUTE,
  FEATURED_CREATORS_PAGE_ROUTE,
} from '@blank/pages/routeConstants';
import { TRANSACTION_STATUS } from '@blank/common/constants';

import Navigator from './Navigator';
import SlideNav from './SlideNav';
import NewCollection from '@blank/pages/NewCollection';
import MethodForm from '@blank/pages/NewCollection/MethodForm';
// import NotificationDropdown from './NotificationDropdown';
import NumberTransaction from './NumberTransaction';
import Breadcrumbs from '../Breadcrumbs';
import { useHistory } from 'react-router-dom';
import { useUserInfo } from '@blank/pages/shared/ProfileContext';
// import Notification from '@blank/components/Notification';
import SignModal from '@blank/pages/shared/Layout/SignModal';

const LEFT_ICON = [
  {
    key: 'studio',
    label: <Image src={logo} height={32} />,
    href: HOME_ROUTE,
  },
];

const LEFT_NAVS = (isMobile) => {
  const menu = [
    {
      key: 'studio',
      label: <Image src={logo} height={32} />,
      href: HOME_ROUTE,
    },
  ];
  return menu;
};

const CENTER_NAVS = [
  {
    key: 'breadcrumbs',
    label: <Breadcrumbs />,
  },
];

const NAVBAR_MENU = [
  // {
  //   key: 'activity',
  //   label: (
  //     <Link color="#808080" fontSize="20px" to={EXPLORE_ROUTE}>
  //       activity
  //     </Link>
  //   ),
  // },
  {
    key: 'collections',
    label: (
      <Link
        color="text"
        fontSize="14px"
        letterSpacing="0.01em"
        to={FEATURED_COLLECTIONS_PAGE_ROUTE}
      >
        Collections
      </Link>
    ),
  },
  {
    key: 'creators',
    label: (
      <Link
        color="text"
        fontSize="14px"
        letterSpacing="0.01em"
        to={FEATURED_CREATORS_PAGE_ROUTE}
      >
        Creators
      </Link>
    ),
  },
];

const DAPP_RIGHT_NAVS = (
  whiteListMember,
  setLaunchSM,
  setOpenColTypeModal,
  isMobile
) => [
  ...(isMobile ? [] : NAVBAR_MENU),
  // {
  //   key: 'bid_offer',
  //   label: <Notification />,
  // },
  // {
  //   key: 'notifications',
  //   label: <NotificationDropdown />,
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  // },
  {
    key: 'user',
    label: (
      <SlideNav
        whiteListMember={whiteListMember}
        setLaunchSM={setLaunchSM}
        setOpenColTypeModal={setOpenColTypeModal}
      />
    ),
  },
];

const RIGHT_NAVS = (isMobile) => [
  ...(isMobile ? [] : NAVBAR_MENU),
  {
    key: 'connenct',
    label: <ConnectButton />,
  },
];

const headerStyles = {
  height: 110,
  px: 40,
  maxWidth: 'inherit',
  width: '100%',
  position: 'fixed',
  top: '0',
  left: '0',
  zIndex: '10',
  bg: 'background',
  fontFamily: 'normal',
};
const hideBreadcumbList = [HOME_ROUTE, EXPLORE_ROUTE, LANDING_ROUTE];
const hideNavList = [
  MINT_ROUTE,
  ENABLE_COMING_SOON ? HOME_ROUTE : undefined,
].filter((r) => !!r);
const mintRoutes = [MINT_ROUTE];

const Header = ({ dapp }) => {
  const { isVerifyArtist: whiteListMember } = useUserInfo();
  const [launchSM, setLaunchSM] = useState();
  const [openColTypeModal, setOpenColTypeModal] = useState();
  const location = useLocation();
  const account = useAccount();
  const history = useHistory();
  const isMobile = useMediaQuery('only screen and (max-width: 768px)');

  const showBreadCrumb = () => {
    return !hideBreadcumbList.includes(location.pathname) && !isMobile;
  };
  const showNavigation = !hideNavList.includes(location.pathname);
  const isMintPage = mintRoutes.includes(location.pathname);
  return (
    <>
      {!account ? (
        <Flex
          center
          {...headerStyles}
          css={`
            @media (min-width: 320px) and (max-width: 767px) {
              padding-left: 22px;
              padding-right: 22px;
            }
          `}
          fontFamily="normal"
        >
          <Flex flex={1} justifyContent="flex-start" zIndex={2}>
            <Navigator
              navs={LEFT_NAVS(isMobile)}
              gap={48}
              fontSize={18}
              color="primaryLight"
            />
            {showBreadCrumb() && (
              <Navigator
                navs={CENTER_NAVS}
                fontSize={18}
                color="primaryLight"
              />
            )}
          </Flex>
          {/* {showNavigation && (
            <Flex flex={1} justifyContent="flex-end" zIndex={2}>
              <Navigator navs={RIGHT_NAVS(isMobile)} gap={20} color="#000" />
            </Flex>
          )} */}
        </Flex>
      ) : (
        <Flex
          center
          {...headerStyles}
          bg="background"
          css={`
            @media (min-width: 320px) and (max-width: 767px) {
              padding-left: 22px;
              padding-right: 22px;
            }
          `}
        >
          <Flex flex={1} justifyContent="flex-start" zIndex={2}>
            <Navigator
              navs={isMintPage ? LEFT_ICON : LEFT_NAVS(isMobile)}
              fontSize={18}
              gap={48}
              color="primaryLight"
            />
            {showBreadCrumb() && (
              <Navigator
                navs={CENTER_NAVS}
                fontSize={18}
                gap={48}
                color="primaryLight"
              />
            )}
          </Flex>

          <Flex flex={1} justifyContent="flex-end" zIndex={2}>
            <Flex>
              {dapp && <NumberTransaction type={TRANSACTION_STATUS.error} />}
              {dapp && <NumberTransaction type={TRANSACTION_STATUS.success} />}
              {dapp && <NumberTransaction type={TRANSACTION_STATUS.pending} />}
            </Flex>
            {isMintPage && (
              <Button
                variant="secondary"
                borderRadius="8px"
                onClick={() => history.goBack()}
              >
                cancel
              </Button>
            )}
            {showNavigation && (
              <Navigator
                navs={
                  dapp
                    ? DAPP_RIGHT_NAVS(
                        whiteListMember,
                        setLaunchSM,
                        setOpenColTypeModal,
                        isMobile
                      )
                    : RIGHT_NAVS(isMobile)
                }
                color="#000"
                fontSize="20px"
                gap="32px"
                center
              />
            )}
          </Flex>
          {!!launchSM && (
            <NewCollection type={launchSM} onClose={() => setLaunchSM()} />
          )}
          {openColTypeModal && (
            <MethodForm
              onClose={() => setOpenColTypeModal(false)}
              onSubmit={setLaunchSM}
            />
          )}
        </Flex>
      )}
      <SignModal />
    </>
  );
};

export default Header;
