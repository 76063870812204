// import { bufferToHex, generateAddress } from 'ethereumjs-util';
import { soliditySHA3 } from 'ethereumjs-abi';
import { web3 } from '@blank/common/constants';
import { MARKET_ADDRESS } from '@blank/constants/network';
// import { web3 } from '@blank/common/constants';

// export const getFutureAddress = async (address) => {
//   try {
//     const txcount = await web3.eth.getTransactionCount(address);
//     console.log('txcount', txcount);
//     const newBuffer = await generateAddress(address, txcount);
//     console.log('newBuffer', newBuffer);
//     const futureAddress = await bufferToHex(newBuffer);
//     return futureAddress;
//   } catch (error) {
//     console.log('[Error] get future address: ', error);
//   }
//   return null;
// };

export const createHashForInvite = async (invitationData) => {
  const soliditySHA3Hash = await soliditySHA3(
    ['address[]', 'uint256', 'uint256', 'address'],
    [
      invitationData.receivers, // receivers
      invitationData.expire_at, // expire_at
      +invitationData.nonce, // invitation
      invitationData.contract, // contract
    ]
  ).toString('hex');
  return `0x${soliditySHA3Hash}`;
};

export const createHashForSetPrice = async (orderData) => {
  const BN = web3.utils.BN;
  const soliditySHA3Hash = await soliditySHA3(
    ['address', 'uint256[]', 'uint256', 'uint256', 'address', 'address'],
    [
      orderData.collection, // collection
      orderData.assets, // assets
      new BN(orderData.price), // price
      +orderData.nonce, // nonce
      orderData.erc20, // erc20
      MARKET_ADDRESS, // contract
    ]
  ).toString('hex');
  return `0x${soliditySHA3Hash}`;
};
