import { lensPath, propOr, set } from 'ramda';
import { useCallback, useEffect, useReducer } from 'react';
import {
  useLogin,
  useRemoveJwt,
  useJwtLSKey,
} from '@blank/pages/shared/JwtContext';
// import { redirect404 } from '@blank/utils';

const initialState = {
  loading: false,
};

const FETCH_START = 'FETCH_START';
const FETCH_SUCCESS = 'FETCH_SUCCESS';
const FETCH_FAILED = 'FETCH_FAILED';
export const RESET_FETCH_DATA = 'RESET_FETCH_DATA';
const STATUS = {
  failed: 0,
  success: 1,
};

const reducer = (state, action) => {
  switch (action.type) {
    case FETCH_START:
      return { loading: true };
    case FETCH_SUCCESS:
      return { loading: false, data: action.payload };
    case FETCH_FAILED:
      return { loading: false, error: action.payload };
    case RESET_FETCH_DATA:
      return initialState;
    default:
      return state;
  }
};
const getCompleteUrl = (url) =>
  `${url[0] === '/' ? process.env.REACT_APP_BACKEND_DOMAIN : ''}${url}`;

export const setJwtToken = (jwtToken) =>
  set(lensPath(['headers', 'api-key']), jwtToken);

export const useFetcher = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const jwtKey = useJwtLSKey();
  // const [jwtToken] = useContext(JwtContext);
  const [callLogin] = useLogin();
  const { loading, data, error } = state;
  const removeJwt = useRemoveJwt();

  const triggerFetch = useCallback(
    async (url, { jwt, isCallLogin, ...opts } = {}) => {
      if (!url) return Promise.resolve();
      let newToken = null;
      dispatch({ type: FETCH_START });
      const jwtToken = localStorage.getItem(jwtKey);
      if ((jwt && !jwtToken) || isCallLogin) {
        try {
          newToken = await callLogin();
        } catch (error) {
          dispatch({ type: FETCH_FAILED, payload: error });
          return error;
        }
      }
      return fetch(
        getCompleteUrl(url),
        jwt ? setJwtToken(jwtToken || newToken)(opts) : opts
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            const error = new Error('API returns errors.');
            error.status = response.status;
            throw error;
          }
        })
        .then((data) => {
          if (data.status === STATUS.failed) {
            throw data.error;
          } else {
            dispatch({
              type: FETCH_SUCCESS,
              payload: propOr(data, 'data')(data),
            });
            // if (isEmpty(data?.data)) {
            //   redirect404();
            // }
            if (data?.prints) {
              return data?.prints;
            }
            return data.data;
          }
        })
        .catch((error) => {
          if (jwt && error.code === 401) {
            removeJwt();
          }
          dispatch({ type: FETCH_FAILED, payload: error });
          return error;
        });
    },
    [callLogin, jwtKey, removeJwt]
  );

  return [triggerFetch, { loading, data, error, dispatch }];
};

export const useFetch = (url, opts) => {
  const [fetcher, result] = useFetcher();

  const triggerFetch = useCallback(() => {
    return fetcher(url, opts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetcher, url, JSON.stringify(opts)]);

  return [triggerFetch, result];
};

export const useFetchOnMount = (url, opts) => {
  const [triggerFetch, result] = useFetch(url, opts);

  useEffect(() => {
    triggerFetch();
  }, [triggerFetch]);

  return result;
};
