import React from 'react';
import { Flex, Text, Input, Icon } from '@blank/atoms';
import Dropdown from '@blank/components/Dropdown';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { useFetchOnMount } from '@blank/hooks/useFetch';
import { truncateAddress, addressEquals } from '@blank/utils';
import { isEmpty, filter } from 'ramda';
import { useAccount } from '@blank/common/WalletContext/hooks';
import { findIndex } from 'ramda';
import { propEq } from 'ramda';
import CopyButton from '@blank/components/CopyButton';

const getValidUser = (data, account, invites) => {
  const otherUsers = data?.accounts || [];
  let validUsers = filter((user) => {
    const findAdded = findIndex(propEq('address', user.address))(invites);
    return !addressEquals(user.address, account) && findAdded < 0;
  }, otherUsers);

  if (isEmpty(data?.accounts)) {
    validUsers = [{ address: data.search_text }];
  }
  return validUsers;
};

const SearchInviteField = ({ fieldName, collectionKey, isRemove }) => {
  const account = useAccount();
  const { values, setFieldValue } = useFormikContext();
  const invites = values[fieldName];
  const [searchText, setSearchText] = useState();
  const searchCollection = collectionKey ? `collection=${collectionKey}&` : '';
  const { data, loading } = useFetchOnMount(
    `/api/users/search?${searchCollection}searchText=${searchText}`
  );
  const validUsers = getValidUser(data, account, invites);

  const removeInviteAddress = (rmAdd) => {
    setFieldValue(
      fieldName,
      filter((add) => !addressEquals(rmAdd, add.address))(invites)
    );
  };

  const getEvent = (item) => {
    let rerult = '';
    if (isRemove) {
      rerult = item.status === 1 ? '' : 'none';
    } else {
      rerult = item.status === 1 ? 'none' : '';
    }
    return rerult;
  };

  return (
    <Flex
      flexDirection="column"
      position="relative"
      mb={20}
      width="100%"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Dropdown
        alwaysOpen
        position="left"
        display="flex"
        alignItems="center"
        // autoClose
        openProps={{
          width: '100%',
          top: 'calc(100% + 1px)',
        }}
        wrapperProps={{
          mb: 10,
          width: '100%',
        }}
        width="100%"
        textDecoration="none !important"
        flexDirection="column"
        // eslint-disable-next-line react/jsx-no-duplicate-props
        alignItems="baseline"
        content={
          !isEmpty(validUsers) && (
            <Flex
              flexDirection="column"
              width="100%"
              bg="background"
              border="0.5px solid rgba(0, 0, 0, 0.2)"
              box-shadow="0px 2px 6px rgba(0, 0, 0, 0.06)"
              borderRadius="8px"
              pb="0px"
              textAlign="left"
            >
              {validUsers.map((item) => (
                <Text
                  key={item.address}
                  fontWeight="400"
                  fontSize={16}
                  lineHeight="140%"
                  cursor="pointer"
                  py={10}
                  px={20}
                  mr="0px"
                  onClick={() => {
                    setTimeout(() => {
                      setFieldValue(
                        fieldName,
                        invites.includes(item.address)
                          ? invites
                          : [...invites, item]
                      );
                      setSearchText('');
                    }, 200);
                  }}
                  pointerEvents={getEvent(item)}
                  css={{
                    ':hover': {
                      background: 'rgba(0, 0, 0, 0.1)',
                    },
                  }}
                  width="100%"
                >
                  {!!item.username ? (
                    <Text
                      textOverflow="ellipsis"
                      overflow="hidden"
                      maxWidth="400px"
                      display="block"
                    >
                      <b>@{item.username}</b> {truncateAddress(item.address)}
                    </Text>
                  ) : (
                    <b>{truncateAddress(item.address)}</b>
                  )}
                  {item.status === 1 && (
                    <Text color="#17C25F" fontWeight={700} ml="17px">
                      Invited
                    </Text>
                  )}
                </Text>
              ))}
            </Flex>
          )
        }
      >
        <>
          <Input
            placeholder="Search by artist name or paste address"
            autoFocus
            borderColor="primaryLight"
            borderRadius="8px"
            width="100%"
            height="45px"
            padding="0 25px"
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
            }}
          />
          {isEmpty(validUsers) && !loading && !!searchText && (
            <Text
              fontStyle="italic"
              fontSize="12px"
              lineHeight="100%;"
              color="red"
              width="100%"
              mt="10px"
              textAlign="left"
            >
              There is no result for {searchText}. Please try again.
            </Text>
          )}
        </>
      </Dropdown>

      <Flex
        justifyContent="center"
        alignItems="flex-start"
        flexDirection="column"
        width="100%"
        mt="20px"
      >
        {invites.map(({ address, username }) => (
          <Flex
            width="100%"
            justifyContent="flex-start"
            alignItems="center"
            flexDirection="row"
            my="10px"
          >
            <Icon
              name="x_remove"
              size={18}
              onClick={() => {
                setTimeout(() => {
                  setSearchText('');
                  removeInviteAddress(address);
                }, 200);
              }}
              cursor="pointer"
              mr={15}
            />
            {username && (
              <Text
                mr="10px"
                fontWeight={600}
                fontSize={18}
                lineHeight="100%"
                textOverflow="ellipsis"
                overflow="hidden"
                maxWidth="200px"
              >
                {username}
              </Text>
            )}
            <CopyButton
              text={address}
              textDisPlay={truncateAddress(address)}
              fontWeight={username ? 400 : 600}
              fontStyle={username ? 'italic' : 'normal'}
              fontSize={18}
              lineHeight="100%"
            />
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default SearchInviteField;
