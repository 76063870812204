import React from 'react';
import ReactDOM from 'react-dom';
import { Box, Icon } from '../atoms';

const popoverRoot = document.getElementById('popover-root');

const Popover = ({
  children,
  open = false,
  showCloseIcon = true,
  width = '260px',
  centerPosition = false,
  ...props
}) => {
  const popover = open && (
    <Box
      position="absolute"
      width={width}
      height="auto"
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.12)"
      background="#fff"
      zIndex={99}
      className={centerPosition ? 'popover-center' : 'popover'}
      {...props}
    >
      {children}
      {showCloseIcon && (
        <Icon
          name="close"
          size="12px"
          cursor="pointer"
          position="absolute"
          top="9px"
          right="10px"
        />
      )}
    </Box>
  );

  return ReactDOM.createPortal(popover, popoverRoot);
};

export default Popover;
