import React from 'react';

import { Button, Text, Flex } from '@blank/atoms';
import Modal from '@blank/components/Modal';
import CopyButton from '@blank/components/CopyButton';
import { truncateAddress } from '@blank/utils';

const InviteSuccessModal = ({ open, onClose, invites = [], ...props }) => {
  return (
    <Modal
      open={open}
      alignItems="center"
      p={32}
      width={547}
      height={442}
      {...props}
    >
      <Text
        fontWeight="bold"
        fontSize={28}
        lineHeight="140%"
        color="text"
        textAlign="center"
        mb={26}
      >
        New Members Successfully Invited
      </Text>
      <Text
        fontSize={16}
        lineHeight="160%"
        mb={30}
        px="30px"
        textAlign="center"
      >
        Invitation sent. Please note that the user needs to accept your
        {` `}invitation in order to join this collab.
      </Text>
      <Flex
        justifyContent="center"
        alignItems="flex-start"
        flexDirection="column"
        width="100%"
        mb={40}
      >
        {invites.map(({ address, username }) => (
          <Flex
            width="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
            my="10px"
          >
            {username && (
              <Text
                mr="20px"
                fontWeight={600}
                fontSize={18}
                lineHeight="100%"
                textOverflow="ellipsis"
                overflow="hidden"
              >
                @{username}
              </Text>
            )}
            <CopyButton
              text={address}
              textDisPlay={truncateAddress(address)}
              fontWeight={username ? 400 : 600}
              fontStyle={username ? 'italic' : 'normal'}
              fontSize={18}
              lineHeight="100%"
            />
          </Flex>
        ))}
      </Flex>
      <Button
        variant="link"
        fontWeight="bold"
        fontSize={16}
        lineHeight="170%"
        onClick={onClose}
      >
        OK
      </Button>
    </Modal>
  );
};

export default InviteSuccessModal;
