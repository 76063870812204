import ArtBounty from '@blank/contracts/ArtBounty.json';
import BlankCollectionFactory from '@blank/contracts/BlankCollectionFactory.json';
import ERC721Market from '@blank/contracts/ERC721Market.json';
import BlankOpenEdition from '@blank/contracts/BlankOpenEdition.json';
import BlankOpenEditionCollection from '@blank/contracts/BlankOpenEditionCollection.json';
import Auction from '@blank/contracts/Auction';
import Erc20 from '@blank/contracts/ERC20.json';

import {
  BOUNTY_ADDRESS,
  FACTORY_ADDRESS,
  MARKET_ADDRESS,
  OPENEDITION_ADDRESS,
  OPENEDITION_COLLECTION_ADDRESS,
  AUCTION_ADDRESS,
  ERC20_ADDRESS,
} from '@blank/constants/network';

export const FACTORY_NAME = 'BlankCollectionFactory';
export const CRYPTOPUNK_NAME = 'Cryptopunk';
export const MARKET_NAME = 'ERC721Market';
export const ARTBOUNTY_NAME = 'ArtBounty';
// export const BLANK_NFT_NAME = 'BlankNFT';
export const OPENEDITION_NAME = 'OpenEdition';
export const OPENEDITION_COLLECTION_NAME = 'OpenEditionCollection';
export const AUCTION_NAME = 'Auction';
export const ERC20_NAME = 'ecr20';

export const CONTRACT_INFOS = [
  {
    name: FACTORY_NAME,
    abi: BlankCollectionFactory,
    address: FACTORY_ADDRESS,
  },
  {
    name: MARKET_NAME,
    abi: ERC721Market,
    address: MARKET_ADDRESS,
  },
  {
    name: ARTBOUNTY_NAME,
    abi: ArtBounty.abi,
    address: BOUNTY_ADDRESS,
  },
  // {
  //   name: BLANK_NFT_NAME,
  //   abi: ArtBounty.abi,
  //   address: BOUNTY_ADDRESS,
  // },
  {
    name: OPENEDITION_NAME,
    abi: BlankOpenEdition,
    address: OPENEDITION_ADDRESS,
  },
  {
    name: OPENEDITION_COLLECTION_NAME,
    abi: BlankOpenEditionCollection,
    address: OPENEDITION_COLLECTION_ADDRESS,
  },
  {
    name: AUCTION_NAME,
    abi: Auction,
    address: AUCTION_ADDRESS,
  },
  {
    name: ERC20_NAME,
    abi: Erc20,
    address: ERC20_ADDRESS,
  },
];
