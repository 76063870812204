import { useContext } from 'react';
import WalletContext from '..';

export const useConnectWallet = () => {
  const { onConnect } = useContext(WalletContext);
  return onConnect;
};

export const useResetWallet = () => {
  const { resetWallet } = useContext(WalletContext);
  return resetWallet;
};

export const useGetDataWallet = () => {
  const { walletInfo } = useContext(WalletContext);
  return walletInfo;
};

export const useGetConnecting = () => {
  const { isConnecting } = useContext(WalletContext);
  return isConnecting;
};

export const useAccount = () => {
  const { walletInfo } = useContext(WalletContext);
  return walletInfo?.address;
};

export const useBalance = () => {
  const { walletInfo } = useContext(WalletContext);
  return walletInfo?.balance;
};

export const useCheckWaitingLogin = () => {
  const { walletInfo } = useContext(WalletContext);
  const cachedProvider = walletInfo?.cachedProvider;
  return cachedProvider && !walletInfo?.address;
};

export const useWrongNetwork = () => {
  const { walletInfo } = useContext(WalletContext);
  return walletInfo?.isWrongNetwork;
};

export const useContractInfo = (contractName) => {
  const { contractInfos } = useContext(WalletContext);
  return (contractInfos || {})[contractName];
};

export const useAllContracts = (contractName) => {
  const { contractInfos } = useContext(WalletContext);
  return contractInfos || {};
};

export const useConnecting = () => {
  const { isConnecting, setIsConnecting } = useContext(WalletContext);
  return { isConnecting, setIsConnecting };
};
