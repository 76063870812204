import React from 'react';

import { Box, Flex, Link } from '../../../atoms';
import Navigator from './Navigator';
import {
  PRIVACY_ROUTE,
  TERM_OF_SERVICE,
  // FAQ_ROUTE,
  // ABOUT_ROUTE,
} from '@blank/pages/routeConstants';
import { navStyle } from '@blank/constants/styles';

const CENTER_NAVS = [
  {
    key: 'tos',
    label: 'Terms & Conditions',
    href: TERM_OF_SERVICE,
    pages: [],
  },
  {
    key: 'privacy',
    label: 'Privacy',
    href: PRIVACY_ROUTE,
    pages: [],
  },
  // {
  //   key: 'FAQ',
  //   label: 'FAQ',
  //   href: FAQ_ROUTE,
  //   pages: [],
  // },
  // {
  //   key: 'About',
  //   label: 'About',
  //   href: ABOUT_ROUTE,
  //   pages: [],
  // },
];

const SOCIAL_NAVS = [
  {
    key: 'discord',
    label: 'Discord',
    href: 'https://discord.gg/KmdxWqzVBH',
    external: true,
  },
  {
    key: 'instagram',
    label: 'Instagram',
    href: 'https://www.instagram.com/blankdotart/ ',
    external: true,
  },
  {
    key: 'twitter',
    label: 'Twitter',
    href: 'https://twitter.com/blankdotart ',
    external: true,
  },
];

const footerStyles = {
  maxWidth: 'inherit',
  width: '100%',
  zIndex: '1',
  bg: 'background',
  fontFamily: 'normal',
};

const dynamicSmallFontSize = {
  css: `
    @media (max-width: 1920px) {
      font-size: 14px;
    }
    font-size: 20px;
  `,
};

const dynamicRegularFontSize = {
  css: `
    @media (max-width: 1920px) {
      font-size: 20px !important;
    }
    font-size: 32px !important;
  `,
};

const Footer = ({ isMobile }) => {
  const date = new Date();
  const year = date.getFullYear();
  if (isMobile) {
    return (
      <Box
        fontFamily="normal"
        borderTop="1px solid rgba(0,0,0,0.2)"
        pt="24px"
        mx="20px"
      >
        <Box fontSize="14px">
          <Flex fontSize="14px" color="textGray">
            © {year} / All Rights Reserved Blank.Art
          </Flex>
          {CENTER_NAVS.map((nav) => (
            <Flex>
              <Link to={nav.href}>{nav.label}</Link>
            </Flex>
          ))}
        </Box>
        <Box pb="105px" pt="20px">
          {/* <Text color="#757575">Join our community</Text> */}
          <Navigator
            navs={SOCIAL_NAVS}
            propsnav={{ ml: 0 }}
            display="grid !important"
            fontSize="18px"
          />
        </Box>
      </Box>
    );
  }

  return (
    <Flex
      center
      {...footerStyles}
      bg="background"
      fontFamily="normal"
      pb="30px"
    >
      <Flex
        {...navStyle}
        justifyContent="space-between"
        borderTop="1px solid rgba(0,0,0,0.2)"
        mx="40px"
        pt="40px"
      >
        {/* <Flex width="35%">
          <Box
            {...dynamicSmallFontSize}
            lineHeight="140%"
            minWidth="200px"
            pt="7px"
          >
            © {year} / All Rights Reserved Blank.Art
          </Box>
        </Flex> */}
        <Flex
          flexDirection="column"
          width="75%"
          alignItems={'flex-start'}
          {...dynamicSmallFontSize}
        >
          {CENTER_NAVS.map((nav) => (
            <Link to={nav.href}>{nav.label}</Link>
          ))}
          <Flex color="textGray">© {year} / All Rights Reserved Blank.Art</Flex>
        </Flex>
        <Flex justifyContent="center" width="25%">
          {/* <Box mr="25px" color="#757575" position="relative" top="3px">
            Join our community
          </Box> */}
          <Navigator
            navs={SOCIAL_NAVS}
            gap={20}
            {...dynamicRegularFontSize}
            propsnav={{ ml: 0 }}
            display="grid !important"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Footer;
