import { COLLECTION_TYPES } from '@blank/constants';
import {
  VOLATILITY_ART_LOGIC_ADDRESS,
  VOLATILITY_COLECTION_V1_ADDRESS,
  SOLO_LOGIC_ADDRESS_V2,
  COLLAB_LOGIC_ADDRESS_V2,
  GROUP_LOGIC_ADDRESS_V2,
} from '@blank/constants/network';
import { web3 } from '@blank/common/constants';

export const getLogicAddressByCollectionType = (type) => {
  switch (type) {
    case COLLECTION_TYPES.collabs.type:
      return COLLAB_LOGIC_ADDRESS_V2;
    case COLLECTION_TYPES.creations.type:
      return SOLO_LOGIC_ADDRESS_V2;
    case COLLECTION_TYPES.groups.type:
      return GROUP_LOGIC_ADDRESS_V2;
    case COLLECTION_TYPES.collabsV2.type:
      return COLLAB_LOGIC_ADDRESS_V2;
    case COLLECTION_TYPES.creationsV2.type:
      return SOLO_LOGIC_ADDRESS_V2;
    case COLLECTION_TYPES.groupsV2.type:
      return GROUP_LOGIC_ADDRESS_V2;
    case COLLECTION_TYPES.volatilityArt.type:
      return VOLATILITY_ART_LOGIC_ADDRESS;
    default:
      break;
  }
  return null;
};

// Solo and Solo v2 collection
export const createCreationMemoryData = async ({
  creator,
  name,
  symbol = '',
}) => {
  const data = await web3.eth.abi.encodeFunctionCall(
    {
      name: 'initialize',
      type: 'function',
      inputs: [
        {
          type: 'address',
          name: 'creator',
        },
        {
          type: 'string',
          name: 'name',
        },
        {
          type: 'string',
          name: 'symbol',
        },
      ],
    },
    [creator, name, symbol]
  );
  return data;
};

// Volatility migrate collection
export const createVolatilityMemoryData = async ({
  creator,
  name,
  symbol = '',
}) => {
  const data = await web3.eth.abi.encodeFunctionCall(
    {
      name: 'initialize',
      type: 'function',
      inputs: [
        {
          type: 'address',
          name: 'v1Contract',
        },
        {
          type: 'address',
          name: 'creator',
        },
        {
          type: 'string',
          name: 'name',
        },
        {
          type: 'string',
          name: 'symbol',
        },
      ],
    },
    [VOLATILITY_COLECTION_V1_ADDRESS, creator, name, symbol]
  );
  return data;
};

// Collab collection
export const createCollabMemoryData = async ({
  creator,
  name,
  symbol = '',
  primaryRoyalty,
  secondaryRoyalty,
}) => {
  const BN = web3.utils.BN;
  const data = await web3.eth.abi.encodeFunctionCall(
    {
      name: 'initialize',
      type: 'function',
      inputs: [
        {
          type: 'address',
          name: 'creator',
        },
        {
          type: 'string',
          name: 'name',
        },
        {
          type: 'string',
          name: 'symbol',
        },
        {
          type: 'uint128',
          name: 'primaryRoyalty',
        },
        {
          type: 'uint128',
          name: 'secondaryRoyalty',
        },
      ],
    },
    [creator, name, symbol, new BN(primaryRoyalty), new BN(secondaryRoyalty)]
  );
  return data;
};

// Group collection
export const createGroupMemoryData = async ({
  creator,
  name,
  symbol = '',
  // primaryCommission,
  // secondaryCommission,
}) => {
  // const BN = web3.utils.BN;
  const data = await web3.eth.abi.encodeFunctionCall(
    {
      name: 'initialize',
      type: 'function',
      inputs: [
        {
          type: 'address',
          name: 'creator',
        },
        {
          type: 'string',
          name: 'name',
        },
        {
          type: 'string',
          name: 'symbol',
        },
        // {
        //   type: 'uint128',
        //   name: 'primaryCommission',
        // },
        // {
        //   type: 'uint128',
        //   name: 'secondaryCommission',
        // },
      ],
    },
    [
      creator,
      name,
      symbol,
      // new BN(primaryCommission),
      // new BN(secondaryCommission),
    ]
  );
  return data;
};
