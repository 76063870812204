import React from 'react';

import { Flex } from '@blank/atoms';
// import HeaderComingSoon from './HeaderComingSoon';
import Header from './Header';
import Footer from './Footer';
// import FooterComingSoon from './FooterComingSoon';
import useObserver from '@blank/hooks/useObserver';
// import Notification from '@blank/components/Notification';

const Layout = ({ dapp, children, bodyProps, ...props }) => {
  const [matches, ref] = useObserver();
  const isMobile = matches.tablet || matches.mobile;

  return (
    <Flex
      flexDirection="column"
      minHeight="100vh"
      bg="background"
      color="text"
      fontSize={4}
      fontFamily="normal"
      pt={90}
      ref={ref}
      {...props}
    >
      <Header dapp={dapp} matches={matches} />
      <Flex flex={1} overflowX="auto" {...bodyProps}>
        {children}
      </Flex>
      <Footer dapp={dapp} isMobile={isMobile} />
      {/* <Notification /> */}
    </Flex>
  );
};

export default Layout;
