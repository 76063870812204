import { MAINNET, RINKEBY } from './network';

export const METAMASK = 'metamask';
export const PORTIS = 'portis';
export const WALLET_CONNECT = 'walletconnect';
export const FORTMATIC = 'fortmatic';

const walletConfig = {
  [MAINNET]: {
    INFURA_ID: '21ebe584efce4ebba81a53e8ebe3c466',
    INFURA_ENDPOINT:
      'https://mainnet.infura.io/v3/21ebe584efce4ebba81a53e8ebe3c466',
    FORTMATIC_KEY: 'pk_live_84113B9858EF15B7',
    PORTIS_KEY: '5736dfd1-2cd3-4eb9-bb36-0f1361425b2f',
  },
  [RINKEBY]: {
    INFURA_ID: '21ebe584efce4ebba81a53e8ebe3c466',
    INFURA_ENDPOINT:
      'https://rinkeby.infura.io/v3/21ebe584efce4ebba81a53e8ebe3c466',
    FORTMATIC_KEY: 'pk_test_E8BCF8AB6268E008',
    PORTIS_KEY: '5736dfd1-2cd3-4eb9-bb36-0f1361425b2f',
  },
};

export const {
  FORTMATIC_KEY,
  PORTIS_KEY,
  INFURA_ID,
  INFURA_ENDPOINT,
} = walletConfig[process.env.REACT_APP_NETWORK || MAINNET];
