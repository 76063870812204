import { prop } from 'ramda';
import React, { createContext, useCallback } from 'react';

import {
  useFetchWithoutJwt,
  usePostWithoutJwt,
} from '@blank/hooks/useFetchWithoutJwt';
import { useWeb3 } from '@blank/common/WalletContext/hooks/useWeb3';
import { useAccount } from '@blank/common/WalletContext/hooks';

const JwtContext = createContext([]);

export const useJwtLSKey = () => {
  const account = useAccount();

  return `jwt-${account}`;
};

export const JwtProvider = ({ children }) => {
  // const key = useJwtLSKey();
  // const [jwtToken, setJwtToken] = useState(null);
  // useEffect(() => {
  //   setJwtToken(localStorage.getItem(key));
  // }, [key]);

  return <JwtContext.Provider value={[]}>{children}</JwtContext.Provider>;
};

export const useUpdateJwt = () => {
  const key = useJwtLSKey();
  // const [, setJwtToken] = useContext(JwtContext);

  return useCallback(
    (token) => {
      // setJwtToken(token);
      localStorage.setItem(key, token);
    },
    [key]
  );
};

export const useLogin = () => {
  const web3 = useWeb3();
  const account = useAccount();
  // const key = useJwtLSKey();

  // const [jwtToken] = useContext(JwtContext);
  // const jwtToken = localStorage.getItem(key);

  const [auth] = useFetchWithoutJwt('/api/auth/message');

  const [login, { data: loginData }] = usePostWithoutJwt('/api/auth/login');

  const token = prop('token')(loginData);
  const updateJwt = useUpdateJwt();

  const callLogin = useCallback(
    () =>
      auth()
        .then((data) => {
          const { message = '' } = data || {};
          if (web3) {
            return web3.eth.personal.sign(
              web3.utils.fromUtf8(message),
              account,
              ''
            );
          }
          throw new Error('web3 is undefined');
        })
        .then((signature) =>
          login({
            signer: account,
            signature,
          })
        )
        .then(({ token }) => {
          updateJwt(token);
          return token;
        }),
    [account, auth, login, updateJwt, web3]
  );

  return [callLogin, { data: { token } }];
};

export const useRemoveJwt = () => {
  const key = useJwtLSKey();
  // const [, setJwtToken] = useContext(JwtContext);

  return useCallback(() => {
    // setJwtToken(null);
    localStorage.removeItem(key);
  }, [key]);
};

export default JwtContext;
