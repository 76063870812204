import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Modal from '@blank/components/Modal';
import { Anchor, Box, Flex, Icon, Link, Text } from '@blank/atoms';
import {
  useGetDataWallet,
  useResetWallet,
  useConnecting,
} from '@blank/common/WalletContext/hooks';
import { useLogin, useJwtLSKey } from '@blank/pages/shared/JwtContext';
import { LANDING_ROUTE } from '@blank/pages/routeConstants';

const SignModal = () => {
  const walletInfo = useGetDataWallet();
  const jwtKey = useJwtLSKey();
  const [callLogin] = useLogin();
  const history = useHistory();
  const resetWallet = useResetWallet();
  const { isConnecting, setIsConnecting } = useConnecting();
  const jwtToken = localStorage.getItem(jwtKey);
  const [isSign, setIsSign] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const signin = async () => {
    if (isLoading) return;
    setLoading(true);
    try {
      await callLogin();
      setLoading(false);
      setIsSign(true);
      setIsConnecting(false);
    } catch (error) {
      setLoading(false);
      setIsSign(true);
      setIsConnecting(false);
    }
  };

  return (
    <Modal
      width={655}
      minHeight={347}
      borderRadius="20px"
      open={walletInfo?.connected && !jwtToken && !isSign && isConnecting}
      onClickOutside={() => {
        setIsSign(true);
        setIsConnecting(false);
      }}
    >
      <Box textAlign="center" py="45px" px="20px" fontFamily="normal">
        <Box fontSize="36px">
          Sign the message <br /> on Metamask to continue
        </Box>
        <Box color="#808080" fontSize="10px">
          <Box mt="10px">By connecting your wallet, you agree to</Box>
          <Box>
            our{` `}
            <Link to="/terms" taget="_blank" textDecoration="underline">
              Terms and Conditions
            </Link>
            {` `}and to our{` `}
            <Link to="privacy" taget="_blank" textDecoration="underline">
              Privacy Policy
            </Link>
          </Box>
          <Flex
            border={`2px solid ${isLoading ? '#EBEBEB' : '#000'}`}
            borderRadius="6px"
            height="52px"
            width="100%"
            position="relative"
            fontSize="18px"
            fontWeight={500}
            center
            color="#000"
            mt="13px"
            mb={isLoading ? '15px' : '32px'}
            cursor="pointer"
            onClick={signin}
            background={isLoading ? '#EBEBEB' : '#fff'}
            fontFamily="normal"
          >
            {isLoading && (
              <Text fontFamily="normal">
                click <Text>Sign</Text> on Metamask
              </Text>
            )}
            {!isLoading && <Text fontFamily="normal">Open Metamask</Text>}
            <Box position="absolute" right="30px">
              {!isLoading && (
                <Icon name="metamask" size={28} position="relative" />
              )}
              {isLoading && (
                <Box className="loading" width="23px" height="23px">
                  <Icon name="loading" size={23} position="relative" />
                </Box>
              )}
            </Box>
          </Flex>
          {isLoading && (
            <Box
              fontSize="18px"
              fontWeight={500}
              textDecoration="underline"
              mb="17px"
              onClick={async () => {
                await resetWallet();
                history.push(LANDING_ROUTE);
              }}
            >
              disconnect
            </Box>
          )}

          <Box>
            if you are new to{` `}
            <Anchor
              href="https://ethereum.org/en/"
              taget="_blank"
              textDecoration="underline"
            >
              Ethereum
            </Anchor>
          </Box>
          <Box mt="3px">learn how to setup your wallet</Box>
          <Box mt="9px">
            blank currently supports Metamask and Wallet Connect.
          </Box>
          <Box>Reach us out in our Discord or email us anytime</Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default SignModal;
