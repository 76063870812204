import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { Link as RouterLink } from 'react-router-dom';

import Box from './Box';
import Icon from './Icon';
import theme from '@blank/theme';

export { Icon, Box };

export const Flex = styled(Box)`
  display: ${(props) => (props.inline ? 'inline-flex' : 'flex')};
  ${(props) =>
    props.center &&
    `
		align-items:center;
		justify-content: center;
  `}
`;

export const Grid = styled(Box)`
  display: grid;
`;

export const Table = (props) => <Box as="table" {...props} />;

const ButtonWrapper = styled(Box)`
  &:hover {
    background: ${(props) => (props.disabled ? '#EBEBEB' : '')};
    box-shadow: ${(props) =>
      props.disabled ? '0px 2px 12px rgba(0,0,0,0.12);' : ''};
  }
  background: ${(props) => (props.disabled ? '#EBEBEB' : '')};
`;

export const Button = (props) => (
  <ButtonWrapper
    as="button"
    cursor="pointer"
    fontSize="inherit"
    fontFamily="normal"
    variantsKey="button"
    color={props.variant === 'secondary' ? '#000' : ''}
    {...props}
  />
);
Button.defaultProps = { variant: 'primary' };

export const Input = forwardRef((props, ref) => {
  return (
    <Box
      ref={ref}
      as="input"
      fontSize="inherit"
      fontFamily="normal"
      variantsKey="input"
      {...props}
    />
  );
});

export const InputGroup = forwardRef((props, ref) => {
  return (
    <Flex
      borderRadius={props.borderRadius}
      bg={props.background}
      border={props.borderWrapper}
      {...props.iconProps}
    >
      <Box
        position="relative"
        top="10px"
        left={props.iconProps.left ?? '5px'}
        mr={props.iconProps.mr ?? '10px'}
        ml={props.iconProps.ml ?? '8px'}
      >
        {props.icon}
      </Box>
      {props.input ? (
        props.input
      ) : (
        <Box
          ref={ref}
          as="input"
          fontSize="inherit"
          fontFamily="normal"
          variantsKey="input"
          {...props}
        />
      )}
      <Box position="relative" top="11px">
        {props.clearIcon}
      </Box>
    </Flex>
  );
});

Input.defaultProps = { variant: 'primary' };

export const Textarea = forwardRef((props, ref) => (
  <Input ref={ref} as="textarea" rows="4" {...props} />
));

export const TextareaVariant = forwardRef((props, ref) => (
  <Box {...props.wrapperProps}>
    <Box {...props.labelProps}>{props.label}</Box>
    <Input
      ref={ref}
      as="textarea"
      rows="4"
      placeholder={props.placeholder}
      {...props}
      {...props.inputProps}
    />
  </Box>
));

export const VerticalInput = forwardRef((props, ref) => (
  <Box {...props.wrapperProps}>
    <Box {...props.labelProps}>{props.label}</Box>
    <Input
      ref={ref}
      placeholder={props.placeholder}
      {...props}
      {...props.inputProps}
    />
  </Box>
));

export const InputInlineLabel = forwardRef((props, ref) => {
  return (
    <Flex {...props.wrapperProps} width="100%">
      <Box {...props.labelProps}>{props.label}</Box>
      <Box
        ref={ref}
        as="input"
        fontSize="inherit"
        fontFamily="normal"
        variantsKey="input"
        {...props}
      />
    </Flex>
  );
});

export const InputSocialsLabel = forwardRef((props, ref) => {
  return (
    <>
      <Box {...props.labelProps}>{props.label}</Box>
      <Box
        mx="1em"
        height="100%"
        width="0px"
        borderRight={`1px solid ${theme.colors.borderLightGrey}`}
      />
      {props.beforeInput && (
        <Box
          fontSize="inherit"
          fontFamily="normal"
          color={theme.colors.textSecondaryLight}
          {...props.beforeInputProps}
        >
          {props.beforeInput}
        </Box>
      )}
      <Input ref={ref} fontFamily="normal" {...props} {...props.inputProps} />
    </>
  );
});

export const Image = forwardRef((props, ref) => (
  <Box
    as="img"
    onError={(e) => {
      e.target.onerror = null;
      e.target.src = props.imageError
        ? props.imageError
        : '/assets/images/default-image.png';
    }}
    ref={ref}
    {...props}
  />
));

export const Video = (props) => <Box as="video" {...props} />;
export const Audio = (props) => <Box as="audio" {...props} />;

export const VideoControls = forwardRef((props, ref) => (
  <Box ref={ref} as="video" {...props} />
));
export const AudioControls = forwardRef((props, ref) => (
  <Box as="audio" ref={ref} {...props} />
));

export const Picture = ({ children, ...props }) => (
  <picture>
    {children}
    <Image {...props} />
  </picture>
);

export const Text = (props) => <Box as="span" variantsKey="text" {...props} />;

export const Anchor = ({ original, ...props }) => {
  const restyleProps = !original && {
    color: 'inherit',
    textDecoration: 'none',
  };

  return <Box as="a" {...restyleProps} {...props} />;
};

export const Link = (props) => <Anchor as={RouterLink} {...props} to="" />;

export const Emoji = (props) => (
  <Box
    className="emoji"
    role="img"
    aria-label={props.label ? props.label : ''}
    aria-hidden={props.label ? 'false' : 'true'}
    {...props}
  >
    {props.symbol}
  </Box>
);
