import { curry, startsWith } from 'ramda';
import { COLLECTION_COLORS, NULL_ADDRESS } from '@blank/constants';
import { isEmpty } from 'ramda';
import { matchPath } from 'react-router-dom';
import {
  CASSANDRA_ADDRESS,
  VOLATILITY_COLECTION_V2_ADDRESS,
  MARCO_COLLAB_ADDRESS,
  SMOKERS_ADDRESS,
  ETHERSCAN_API,
  SANDBOX_ADDRESS,
  SZABOTAGE_ADDRESS,
} from '@blank/constants/network';
import { NOT_FOUND } from '@blank/pages/routeConstants';

export * from './imgResizer';

export const truncateAddress = (address = '') => {
  if (!address) return '';
  const length = address.length;
  const head = address.substring(0, 6);
  const tail = address.substring(length - 4, length);

  return `${head}...${tail}`;
};

export const addressEquals = curry(
  (addr1 = '', addr2 = '') =>
    addr1 && addr2 && addr1.toLowerCase() === addr2.toLowerCase()
);

export const getFileType = (file) => file && (file?.type || '').split('/')[0];

export const getRandomColor = () => {
  const index = Math.floor(Math.random() * 7);
  return COLLECTION_COLORS[index];
};

export const checkExternalUrl = (url) => {
  if (!url) return false;
  return (
    startsWith('http://', url) || startsWith('https://') || startsWith('/'), url
  );
};

export const convertBreakLine = (data = '') => {
  if (!data) return '';
  try {
    let result = JSON.stringify(data) || '';
    result = result.replaceAll('\\n', '</br>');
    return JSON.parse(result);
  } catch (error) {
    return data;
  }
};

export const getHighestBid = (bids = [], ownerAccount) => {
  if (!bids || isEmpty(bids)) return 0;
  let highestBid = 0;
  bids.forEach((bid) => {
    if (
      parseInt(bid.price) > highestBid &&
      !addressEquals(ownerAccount, bid.buyer)
    ) {
      highestBid = parseInt(bid.price);
    }
  });
  return highestBid;
};

export const getHighestErc20 = (bids = [], ownerAccount) => {
  if (!bids || isEmpty(bids)) return 0;
  let highestBid = 0;
  let erc20 = '';
  bids.forEach((bid) => {
    if (
      parseInt(bid.price) > highestBid &&
      !addressEquals(ownerAccount, bid.buyer)
    ) {
      highestBid = parseInt(bid.price);
      erc20 = bid?.erc20;
    }
  });
  return erc20;
};

export const getMatchedRouteAndPath = (routeConfigs, pathname) => {
  for (let item of routeConfigs) {
    const matchResult = matchPath(pathname, item);
    if (matchResult) {
      return {
        ...item,
        ...matchResult,
      };
    }
  }
};

export const getCollectionParam = (collectionKey = '') => {
  if (!collectionKey) return collectionKey;
  if (collectionKey.toLowerCase() === 'volatility') {
    return VOLATILITY_COLECTION_V2_ADDRESS;
  }
  if (collectionKey.toLowerCase() === 'szabotage') {
    return SZABOTAGE_ADDRESS;
  }
  if (
    collectionKey.toLowerCase() === 'cassandra' ||
    collectionKey.toLowerCase() === CASSANDRA_ADDRESS
  ) {
    return CASSANDRA_ADDRESS;
  }
  if (collectionKey.toLowerCase() === 'pdc') {
    return MARCO_COLLAB_ADDRESS;
  }
  if (collectionKey.toLowerCase() === 'thesmokers') {
    return SMOKERS_ADDRESS;
  }

  if (collectionKey.toLowerCase() === 'sandbox') {
    return SANDBOX_ADDRESS;
  }

  return collectionKey;
};

export const redirect404 = () => {
  window.location.href = NOT_FOUND;
};

export const getLastedTransaction = async (account) => {
  const url = `${ETHERSCAN_API}/api?module=account&action=txlist&address=${account}&startblock=0&endblock=99999999&page=1&offset=1&sort=desc&apikey=78D5DINBARAMZ3CXVBRSP87V41GTCRVRKC`;
  const response = await fetch(url);
  const data = await response.json();
  return data?.result[0] || {};
};

export const getEdition = (name = '') => {
  const regex = /\([0-9]/;
  let edition = 'edition 1 of 1';

  if (regex.test(name)) {
    const splitName = name.split(' (');
    edition = `edition ${splitName[1]}`.replace(')', '');
  }

  return edition;
};

export const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const nFormatter = (num = 0, digits) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'Q' },
  ];

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });

  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : Math.round(num * 100000000) / 100000000;
};

export const checkExisSciptTag = (data = '') => {
  const re = /<script\b[^>]*>[\s\S]*?<\/script\b[^>]*>/g;
  const results = data.match(re);
  return results;
};

export const getCoinName = (erc20) => {
  const isErc20 = !addressEquals(NULL_ADDRESS, erc20);
  return isErc20 && !!erc20 ? 'SAND' : 'ETH';
};

export const convertCollection = (collectionKey = '') => {
  if (!collectionKey) return collectionKey;
  if (addressEquals(collectionKey, VOLATILITY_COLECTION_V2_ADDRESS)) {
    return 'volatility';
  }
  if (addressEquals(collectionKey, CASSANDRA_ADDRESS)) {
    return 'cassandra';
  }
  if (addressEquals(collectionKey, MARCO_COLLAB_ADDRESS)) {
    return 'pdc';
  }
  if (addressEquals(collectionKey, SMOKERS_ADDRESS)) {
    return 'thesmokers';
  }
  if (addressEquals(collectionKey, SANDBOX_ADDRESS)) {
    return 'sandbox';
  }

  return collectionKey;
};

export const getEditionValue = (asset) => {
  const { editions = [], id } = asset;
  let result = {};
  for (let i = 0; i < editions.length; i++) {
    if (editions[i].id.toString() === id.toString()) {
      result = editions[i];
    }
  }
  return result;
};
export const getAttrForSupperrare = (metadata = {}) => {
  const objectKeys = [
    'description',
    'height',
    'width',
    'media',
    'name',
    'image',
    'tags',
  ];
  let result = [];
  Object.keys(metadata).map((key, index) => {
    if (objectKeys.indexOf(key) === -1) {
      result.push({
        trait_type: key === 'createdBy' ? 'artist' : key,
        value: metadata[key],
      });
    }
    return index;
  });
  return result;
};

export const getPrefixTag = (tag = '') => {
  if (tag[0] && tag[0] !== '#') return '#';
  return '';
};

export const converAttrObjectToAttay = (attrs) => {
  const objectKeys = [
    'board',
    'name',
    'owner',
    'price',
    'reward',
    'originalPrice',
  ];
  let result = [];
  Object.keys(attrs).map((key, index) => {
    if (objectKeys.indexOf(key) > -1) {
      result.push({
        trait_type: key,
        value: attrs[key],
      });
    }
    return index;
  });
  return result;
};

// export async function findReplacementTx(safeHeightBeforeEthTx, tx, event) {
//   const provider = await web3Modal.connect();
//   // const provider = getEthProvider();
//   // If available connect to rpcUrl to avoid issues with WalletConnectProvider receipt.status
//   const web3 = new Web3(provider.rpcUrl ? provider.rpcUrl : provider)

//   const currentNonce = await web3.eth.getTransactionCount(tx.from, 'latest')

//   // Transaction still pending
//   if (currentNonce <= tx.nonce) return null

//   // Binary search the block of replacement (canceled or speedup) transaction
//   // between safeHeightBeforeEthTx and latest.
//   let replacementTxBlock
//   let maxBlock = await web3.eth.getBlockNumber() // latest: chain head
//   let minBlock = safeHeightBeforeEthTx
//   while (minBlock <= maxBlock) {
//     const middleBlock = Math.floor((minBlock + maxBlock) / 2)
//     const middleNonce = await web3.eth.getTransactionCount(tx.from, middleBlock) - 1
//     if (middleNonce < tx.nonce) {
//       // middleBlock was mined before the tx with broadcasted nonce, so take next block as lower bound
//       minBlock = middleBlock + 1
//     } else if (middleNonce >= tx.nonce) {
//       // The middleBlock was mined after the tx with tx.nonce, so check if the account has a
//       // lower nonce at previous block which would mean that tx.nonce was mined in this middleBlock.
//       if (await web3.eth.getTransactionCount(tx.from, middleBlock - 1) - 1 < tx.nonce) {
//         // Confirm the nonce changed by checking the previous block:
//         // use previous block nonce `>=` broadcasted nonce in case there are multiple user tx
//         // in the previous block. If only 1 user tx, then `===` would work
//         replacementTxBlock = middleBlock
//         break
//       }
//       // Otherwise take the previous block as the higher bound
//       maxBlock = middleBlock - 1
//     }
//   }
//   if (!replacementTxBlock) {
//     const error = 'Could not find replacement transaction. It may be due to a chain reorg.'
//     throw new Error(error)
//   }
//   const block = await web3.eth.getBlock(replacementTxBlock, true)
//   const replacementTx = block.transactions.find(
//     blockTx => blockTx.from.toLowerCase() === tx.from.toLowerCase() && blockTx.nonce === tx.nonce
//   )
//   if (!replacementTx) {
//     const error = 'Error happened while searching replacement transaction.'
//     throw new Error(error)
//   }
//   if (replacementTx.to.toLowerCase() !== tx.to.toLowerCase()) {
//     const error = `Transaction was dropped and replaced by '${replacementTx.hash}'`
//     throw new Error(error)
//   }
//   const receipt = await web3.eth.getTransactionReceipt(replacementTx.hash)
//   if (!receipt.status) {
//     const error = `Transaction was dropped and replaced by a failing transaction: '${replacementTx.hash}'`
//     throw new Error(error)
//   }
//   const tokenContract = new web3.eth.Contract(
//     JSON.parse(event.abi),
//     tx.to
//   )
//   const events = await tokenContract.getPastEvents(event.name, {
//     fromBlock: receipt.blockNumber,
//     toBlock: receipt.blockNumber
//   })
//   const foundEvent = events.find(e => e.transactionHash === replacementTx.hash)
//   if (!foundEvent || !event.validate(foundEvent)) {
//     const error = `Transaction was dropped and replaced by '${replacementTx.hash}'
//       with unexpected event: '${JSON.stringify(event)}'`
//     throw new Error(error)
//   }
//   return receipt
// };
