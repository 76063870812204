import { dropLast, last } from 'ramda';
import { useLocation } from 'react-router-dom';

import React from 'react';

import {
  COLLECTION_ROUTE,
  NEW_COLLECTION_ROUTE,
  FAQ_ROUTE,
  EXPLORE_ROUTE,
  PRIVACY_ROUTE,
  ABOUT_ROUTE,
  WHITE_WALL_ROUTE,
  WHITE_WALL_EXPLORE_COLLECTION_ROUTE,
  COLLECTION_PUBLIC_ROUTE,
  ART_WORK_ROUTE,
  ART_WORK_PUBLIC_ROUTE,
  WHITE_WALL_ART_WORK_ROUTE,
  HOME_ROUTE,
  DEFAULT_ROUTE,
  PREVIEW_BEFORE_MINT,
  TERM_OF_SERVICE,
  LAUCNHCARD_COLLECTION,
  OPEN_EDITION_ASSET_ROUTE,
  OPEN_EDITION_COLLECTION_ROUTE,
} from '@blank/pages/routeConstants';
import routeConfigs from '@blank/pages/routerConfigs';

import { Box, Link, Text, Flex } from '@blank/atoms';
import UserBreadcrumb from './UserBreadcrumb';
import CollectionBreadcrumb from './CollectionBreadcrumb';
import AssetBreadcrumb from './AssetBreadcrumb';
import { getMatchedRouteAndPath } from '@blank/utils';

const getBreadcrumbItems = (routes = [], params) => {
  const { account, tab, collectionKey, id, collectionType } = params;
  const getHref = (route) =>
    route
      .replace(':account', account)
      .replace(':tab', tab)
      .replace(':id', id)
      .replace(':collectionKey', collectionKey)
      .replace(':collectionType', collectionType);

  const labelByRoute = (isLast) => ({
    [HOME_ROUTE]: 'launchpad',
    [LAUCNHCARD_COLLECTION]: (
      <CollectionBreadcrumb isLast={isLast} collectionKey={collectionKey} />
    ),
    [COLLECTION_ROUTE]: (
      <CollectionBreadcrumb isLast={isLast} collectionKey={collectionKey} />
    ),
    [NEW_COLLECTION_ROUTE]: (
      <CollectionBreadcrumb isLast={isLast} collectionKey={collectionKey} />
    ),
    [COLLECTION_PUBLIC_ROUTE]: (
      <CollectionBreadcrumb isLast={isLast} collectionKey={collectionKey} />
    ),
    [OPEN_EDITION_COLLECTION_ROUTE]: (
      <CollectionBreadcrumb isLast={isLast} collectionKey={collectionKey} />
    ),
    [PREVIEW_BEFORE_MINT]: 'preview before mint NFT',
    [DEFAULT_ROUTE]: 'Default',
    [EXPLORE_ROUTE]: '..',
    [PRIVACY_ROUTE]: 'privacy',
    [TERM_OF_SERVICE]: 'Terms & Conditions',
    [FAQ_ROUTE]: 'FAQ',
    [ABOUT_ROUTE]: 'about',
    [WHITE_WALL_ROUTE]: <UserBreadcrumb isLast={isLast} account={account} />,
    [WHITE_WALL_EXPLORE_COLLECTION_ROUTE]: (
      <CollectionBreadcrumb isLast={isLast} collectionKey={collectionKey} />
    ),

    [ART_WORK_ROUTE]: (
      <AssetBreadcrumb isLast={isLast} collectionKey={collectionKey} id={id} />
    ),
    [ART_WORK_PUBLIC_ROUTE]: (
      <AssetBreadcrumb isLast={isLast} collectionKey={collectionKey} id={id} />
    ),
    [WHITE_WALL_ART_WORK_ROUTE]: (
      <AssetBreadcrumb isLast={isLast} collectionKey={collectionKey} id={id} />
    ),
    [OPEN_EDITION_ASSET_ROUTE]: (
      <AssetBreadcrumb isLast={isLast} collectionKey={collectionKey} id={id} />
    ),
    NOT_FOUND: '404 NOT FOUND',
  });

  const labelByRouteLast = labelByRoute(true);
  const labelByRouteNonLast = labelByRoute();

  return routes.map((route, index) => ({
    label:
      index >= routes.length - 1
        ? labelByRouteLast[route]
        : labelByRouteNonLast[route],
    href: getHref(route),
  }));
};

const BREADCRUMBS_NOTFOUND = [EXPLORE_ROUTE, 'NOT_FOUND'];

const Breadcrumbs = ({ ...props }) => {
  const location = useLocation();
  const matched = getMatchedRouteAndPath(routeConfigs, location.pathname);

  const breadcrumbsConfig = matched?.breadcrumbsConfig || BREADCRUMBS_NOTFOUND;
  const params = matched?.params || {};

  const items = getBreadcrumbItems(breadcrumbsConfig, params);
  const lastItem = last(items);

  return (
    <Box
      color="text"
      fontSize="14px"
      letterSpacing="0.01em"
      fontFamily="normal"
      {...props}
    >
      <Flex
        display="flex"
        alignItems="center"
        px="10px"
        pl="32px"
        whiteSpace="nowrap"
      >
        {dropLast(1)(items).map((item, index) => {
          if (index === 0) return <></>;
          return (
            <React.Fragment key={index}>
              <Link
                to={item.href}
                textDecoration="none"
                color="text"
                fontSize="14px"
                letterSpacing="0.01em"
              >
                {item.label}
              </Link>
              <Text
                mx="5px"
                color="text"
                fontSize="14px"
                letterSpacing="0.01em"
              >
                /
              </Text>
            </React.Fragment>
          );
        })}
        <Text color={items.length === 2 ? 'textGray' : 'text'}>
          {lastItem?.label}
        </Text>
      </Flex>
    </Box>
  );
};

export default Breadcrumbs;
