import { ThemeProvider } from 'styled-components';
import GlobalStateProvider from '@blank/globalStateContext';
//import { EtherProvider } from '@blank/etherscanContext';
import React from 'react';
import Spinner from '@blank/components/Spinner';

import { Flex } from './atoms';
import Pages from './pages';
import theme from './theme';

// Copy from https://reactjs.org/docs/error-boundaries.html
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error: error.name };
  }

  componentDidMount() {
    sessionStorage.clear();
  }

  componentDidCatch(error, errorInfo) {
    if (error.name === 'ChunkLoadError') {
      window.location.reload();
    }
  }

  render() {
    const { error, hasError } = this.state;
    if (hasError) {
      return (
        <Flex center fontSize={4} height="100vh">
          {error === 'ChunkLoadError' ? (
            <Spinner mx="auto" />
          ) : (
            'App crashed. Something went wrong!'
          )}
        </Flex>
      );
    }

    return this.props.children;
  }
}

const App = () => (
  <ThemeProvider theme={theme}>
    <ErrorBoundary>
      <GlobalStateProvider>
        {/* <EtherProvider> */}
        <Pages />
        {/* </EtherProvider> */}
      </GlobalStateProvider>
    </ErrorBoundary>
  </ThemeProvider>
);

export default App;
