import React, { useState } from 'react';
import { path } from 'ramda';

import { RELOAD_AFTER_TRANSACTION_TYPES } from '@blank/common/constants';
import useMethodSend from '@blank/common/WalletContext/hooks/useMethodSend';
import { COLLECTION_TYPES, WHITE_WALL_TABS_NAME } from '@blank/constants';
import { FACTORY_NAME } from '@blank/contracts';
import InformationForm from './InformationForm';
import TransactionSubmittedModal from '@blank/components/TransactionSubmittedModal';
import InvitesForm from './InvitesForm';
import { useHistory } from 'react-router-dom';
import { COLLECTION_ROUTE, WHITE_WALL_ROUTE } from '../routeConstants';
import { useAccount } from '@blank/common/WalletContext/hooks';
import { useUserInfo } from '@blank/pages/shared/ProfileContext';

const NewCollection = ({
  type = COLLECTION_TYPES.creations.type,
  onClose,
  waitTx,
}) => {
  const [showInviteModal, setShowInviteModal] = useState(false);
  const isCollab = type === COLLECTION_TYPES.collabs.type;
  const isGroup =
    type === COLLECTION_TYPES.groups.type ||
    type === COLLECTION_TYPES.groupsV2.type;
  const account = useAccount();
  const { isVerifyArtist } = useUserInfo();
  const history = useHistory();
  const profifleTab = isVerifyArtist
    ? WHITE_WALL_TABS_NAME.collections
    : WHITE_WALL_TABS_NAME.mynft;

  const [
    createCollection,
    { status, receipt, hash },
    isSigning,
  ] = useMethodSend(
    FACTORY_NAME,
    'createCollection',
    waitTx
      ? null
      : {
          type: RELOAD_AFTER_TRANSACTION_TYPES.studio,
        }
  );

  const collectionAddress = path([
    'events',
    'CollectionCreated',
    'returnValues',
    'collection',
  ])(receipt);

  if (hash && !waitTx && !isCollab && !isGroup) {
    return (
      <TransactionSubmittedModal
        transactionHash={hash}
        open
        onClose={() => {
          if (
            type === COLLECTION_TYPES.volatilityArt.type ||
            type === COLLECTION_TYPES.creations.type ||
            type === COLLECTION_TYPES.groups.type
          ) {
            history.push(
              WHITE_WALL_ROUTE.replace(':account', account).replace(
                ':tab',
                profifleTab
              )
            );
          }
          onClose();
        }}
        onClickOutside={() => onClose()}
      />
    );
  }

  return showInviteModal ? (
    <InvitesForm
      collectionAddress={collectionAddress}
      onClose={() => {
        if (waitTx) {
          onClose({
            id: collectionAddress.toLowerCase(),
            name: collectionAddress,
          });
        } else {
          onClose();
          history.push(
            COLLECTION_ROUTE.replace(
              ':collectionKey',
              collectionAddress
            ).replace(':collectionType', COLLECTION_TYPES.collabs.typeKey)
          );
        }
      }}
    />
  ) : (
    <InformationForm
      type={type}
      createCollection={createCollection}
      isSigning={isSigning}
      status={status}
      hash={hash}
      onClose={onClose}
      collectionAddress={collectionAddress}
      setShowInviteModal={setShowInviteModal}
    />
  );
};

export default NewCollection;
