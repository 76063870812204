// import { useHistory } from 'react-router-dom';
import React from 'react';

import { Box, Flex, Button } from '../../atoms';
import { NETWORK_NAME } from '../../constants/network';
// import { LANDING_ROUTE } from '../routeConstants';
// import { useRemoveWallet } from '@blank/common/WalletContext';
import Modal from '../../components/Modal';
// import { useResetWallet } from '@blank/common/WalletContext/hooks';

const WrongNetworkModal = () => {
  // const history = useHistory();
  // const resetWallet = useResetWallet();
  const switchEthereumChain = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x1` }],
      });
    } catch (e) {
      console.error(e);
    }
  };

  const switchEthereumRinkeby = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x4` }],
      });
    } catch (e) {
      console.error(e);
    }
  };

  const switchNetwork = () => {
    if (NETWORK_NAME === 'Mainnet') {
      switchEthereumChain();
    } else {
      switchEthereumRinkeby();
    }
  };

  return (
    <Modal open width={520} height={230} p={40} textAlign="center">
      <Flex flexDirection="column" alignItems="center">
        <Box fontSize="40px">Wrong network</Box>
        <Box fontSize="18px" mt="20px">
          Please switch to {NETWORK_NAME}
        </Box>
        <Box my="10px">
          <Button onClick={switchNetwork}>Switch</Button>
        </Box>

        {/* <Box mt={10} mb={28}>
          To access {DOMAIN}, please switch to{' '}
          <Text color="text">{NETWORK_NAME}</Text>
        </Box>
        <Button
          onClick={() => {
            resetWallet();
            history.push(LANDING_ROUTE);
          }}
        >
          Okay
        </Button> */}
      </Flex>
    </Modal>
  );
};

export default WrongNetworkModal;
