import React from 'react';

import { Image } from '../../atoms';
import defaultImage from '../../assets/images/collections/default.svg';

const CollectionImage = ({ isDefault, collection, isBack, ...props }) => {
  const image = isDefault
    ? defaultImage
    : require(`../../assets/images/collections/${collection.key}${
        isBack ? '-back' : ''
      }.svg`);

  return <Image src={image} {...props} />;
};

export default CollectionImage;
