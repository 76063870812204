import React, { useState } from 'react';
// import { isNil } from 'ramda';
// import { useMyCollection } from '../useCollection';
import { Box } from '@blank/atoms';
import { truncateAddress } from '@blank/utils';
import CopyButton from '@blank/components/CopyButton';
import {
  VOLATILITY_COLECTION_V2_ADDRESS,
  MARCO_COLLAB_ADDRESS,
  CASSANDRA_ADDRESS,
  SMOKERS_ADDRESS,
  SANDBOX_ADDRESS,
  SZABOTAGE_ADDRESS,
} from '@blank/constants/network';
import { useGlobalState } from '@blank/globalStateContext';

const CollectionBreadcrumb = ({ collectionKey, isLast }) => {
  const [state] = useGlobalState();
  const [isHover, setIsHover] = useState(false);
  const collectionName = state?.collectionName;
  const VOLATILITY = 'volatility';
  const CASSANDRA = 'cassandra';
  const PDC = 'pdc';
  const SMOKERS = 'thesmokers';
  const SANDBOX = 'sandbox';
  const SZABOTAGE = 'szabotage';

  let colKey = collectionKey;
  if (collectionKey === CASSANDRA_ADDRESS) {
    collectionKey = CASSANDRA;
  }

  switch (colKey) {
    case VOLATILITY:
      colKey = VOLATILITY_COLECTION_V2_ADDRESS;
      break;
    case CASSANDRA:
      colKey = CASSANDRA_ADDRESS;
      break;
    case PDC:
      colKey = MARCO_COLLAB_ADDRESS;
      break;
    case SMOKERS:
      colKey = SMOKERS_ADDRESS;
      break;
    case SANDBOX:
      colKey = SANDBOX_ADDRESS;
      break;
    case SZABOTAGE:
      colKey = SZABOTAGE_ADDRESS;
      break;

    default:
      break;
  }

  const styleShort = {
    maxWidth: isLast ? 250 : 125,
    overflow: 'hidden',
    display: 'block',
    textOverflow: 'ellipsis',
  };
  return (
    <>
      {isHover && isLast ? (
        <CopyButton
          text={colKey}
          textDisPlay={truncateAddress(colKey)}
          width={150}
          onMouseLeave={() => setIsHover(false)}
        />
      ) : (
        <Box
          width={isLast ? 150 : ''}
          onMouseEnter={isLast ? () => setIsHover(true) : null}
          {...styleShort}
        >
          {collectionName || truncateAddress(colKey) || ''}
        </Box>
      )}
    </>
  );
};

export default CollectionBreadcrumb;
