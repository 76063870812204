import React, {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { omit } from 'ramda';

import { useFetchOnMount } from '../../../hooks/useFetch';
import { useLogin } from '../JwtContext';
import { useAccount } from '@blank/common/WalletContext/hooks';

const ProfileContext = createContext([]);

const initialState = {};

const SET_USER = 'SET_USER';
const UPDATE_USER = 'UPDATE_USER';
const UPDATE_ETH_PRICE = 'UPDATE_ETH_PRICE';
const SET_ETH_PRICE = 'SET_ETH_PRICE';

// const url = 'https://api.etherscan.io/api?module=stats&action=ethprice';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case SET_ETH_PRICE:
      return {
        ...state,
        ehtPrice: action.payload,
      };
    case UPDATE_ETH_PRICE:
      return {
        ...state,
        ehtPrice: {
          ...state?.ehtPrice,
          preTimeCall: new Date().getTime(),
        },
        currentTime: new Date().getTime(),
      };
    default:
      return state;
  }
};

export const ProfileProvider = memo(({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useLogin();

  const account = useAccount();
  const { data } = useFetchOnMount(`/api/users/${account}`);

  const isVerifyArtist = data?.collabWhitelist || false;
  const isGroupAdmin = data?.groupWhitelist;
  const register = () =>
    data?.email !== '' &&
    data?.username !== '' &&
    data?.email !== null &&
    data?.username !== null &&
    (data?.username ? true : false) &&
    (data?.email ? true : false);

  // const getEthPrice = async () => {
  //   const response = await fetch(url);
  //   const data = await response.json();
  //   if (data?.message !== 'NOTOK' && data?.result?.ethusd) {
  //     dispatch({
  //       type: SET_ETH_PRICE,
  //       payload: {
  //         preTimeCall: new Date().getTime(),
  //         ethUSDstorage: data?.result.ethusd,
  //       },
  //     });
  //   }
  // };

  // useEffect(() => {
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   getEthPrice();
  // }, []);

  // useEffect(() => {
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   if (!!state?.currentTime) getEthPrice();
  // }, [state?.currentTime]);

  useEffect(() => {
    if (data) {
      dispatch({
        type: SET_USER,
        payload: {
          address: account,
          register: register(),
          isVerifyArtist,
          isGroupAdmin,
          ...data,
        },
      });
    }
  }, [account, data, isVerifyArtist, isGroupAdmin]);

  return (
    <ProfileContext.Provider value={[state, dispatch]}>
      {/* <RegisterModal /> */}
      {children}
    </ProfileContext.Provider>
  );
});

export const useUserInfo = () => {
  const [profileState] = useContext(ProfileContext);

  return omit(['cover'], profileState?.user);
};

export const useUserCover = () => {
  const [profileState] = useContext(ProfileContext);

  return profileState?.user?.cover;
};

export const useUpdateProfile = () => {
  const [, dispatch] = useContext(ProfileContext);

  return useCallback(
    (user) => {
      dispatch({
        type: UPDATE_USER,
        payload: user,
      });
    },
    [dispatch]
  );
};

// export const useUsdPrice = () => {
//   const [state] = useContext(ProfileContext);
//   const { preTimeCall, ethUSDstorage } = state?.ehtPrice || {};
//   return { preTimeCall, ethUSDstorage };
// };

// export const useUpdateUsdPrice = () => {
//   const [, dispatch] = useContext(ProfileContext);
//   return useCallback(() => {
//     dispatch({ type: UPDATE_ETH_PRICE });
//   }, [dispatch]);
// };

export default ProfileContext;
