import React from 'react';

import Modal from './Modal';
import Spinner from './Spinner';

const ProcessingModal = ({
  children = 'Loading...',
  showSpinner = true,
  ...props
}) => (
  <Modal
    width={500}
    height={442}
    p="40px"
    fontWeight="bold"
    textAlign="center"
    {...props}
  >
    {children}
    {showSpinner && <Spinner mx="auto" mt={4} />}
  </Modal>
);

export default ProcessingModal;
