export const LANDING_ROUTE = '/';
export const HOME_ROUTE = '/';
export const ABOUT_ROUTE = '/about';
export const HACKATAO_ROUTE = '/hackatao';
export const FAQ_ROUTE = '/faq';
export const CONTACT_ROUTE = '/contact';
export const PRIVACY_ROUTE = '/privacy';
export const MINT_ROUTE = `/mint`;
export const SETTING_ROUTE = '/setting';
export const EXPLORE_ROUTE = '/explore';
export const EXPLORE_TEST_ROUTE = '/explore-test';
export const DEFAULT_ROUTE = '/default';
export const NOT_FOUND = '/page-not-found';
export const TERM_OF_SERVICE = '/terms';
export const TERM_OLD = '/terms/old';
export const EDIT_PROFILE = '/dashboard/edit-profile';
export const OFFERS = '/dashboard/offers';
export const AUCTIONS = '/dashboard/auctions';
export const GRANTS = '/grant';
export const FEATURED_CREATORS_PAGE_ROUTE = '/featured-creators';
export const FEATURED_COLLECTIONS_PAGE_ROUTE = '/featured-collections';
export const BLOG = '/blog';
export const BLOG_DETAIL = '/blog/:id';
export const LANDING_PAGE = '/landing-page';
export const EMAIL_VERIFICATION_PAGE = '/verifications/email';
export const SIGNING_UP_PAGE = '/Signing-up';
// export const MARKET_ROUTE = '/market';
// export const MARKET_ASSET_ROUTE = `${MARKET_ROUTE}/collections/:collectionKey/asset/:id`;

export const WHITE_WALL_ROUTE = `/show/:account/:tab`;
export const WHITE_WALL_EXPLORE_COLLECTION_ROUTE = `/:collectionKey`;
export const WHITE_WALL_ART_WORK_ROUTE = `/nft/:collectionKey/:id`;
// export const WALL_ASSET_ROUTE = `${WHITE_WALL_ROUTE}/collections/:collectionKey/asset/:id`;

export const VIEWER_ROUTE = '/viewer';

export const COLLECTIONS_ROUTE = `${VIEWER_ROUTE}/studio`;
export const COLLECTION_ROUTE = `/:collectionKey`;
export const NEW_COLLECTION_ROUTE = `/new-collection/:collectionKey`;
export const FEATURED_COLLECTION_ROUTE = `/featured/:collectionKey`;
export const ART_WORK_ROUTE = `/nft/:collectionKey/:id`;

export const COLLECTION_PUBLIC_ROUTE = '/:collectionKey';
export const OPEN_EDITION_COLLECTION_ROUTE = '/open-edition/:collectionKey';
export const LAUCNHCARD_COLLECTION = `/launchcard/:collectionKey`;
export const ART_WORK_PUBLIC_ROUTE = `/nft/:collectionKey/:id`;

export const BOUNTIES_ROUTE = `/bounties`;
export const NEW_BOUNTY_ROUTE = `${BOUNTIES_ROUTE}/new`;
export const BOUNTY_DETAIL_ROUTE = `${BOUNTIES_ROUTE}/detail/:bountyId`;

export const BLANK_ARTISTS_ROUTE = `${COLLECTIONS_ROUTE}/blank1`;

export const PRESIGN_ROUTE = `/cards/12345/sign`;
export const PREVIEW_BEFORE_MINT = `/preview-before-mint`;
export const MINT_SCRIPT_ROUTE = `/mint/12345/script`;

export const RENDER_GLB_ROUTE = `/render-glb`;

export const DISCORD_LINK = 'https://discord.gg/KmdxWqzVBH';
export const TWITTER_LINK = 'https://twitter.com/blankdotart';
export const INSTAGRAM_LINK = 'https://www.instagram.com/blankdotart/';

export const OPEN_EDITION_ASSET_ROUTE = '/open-edition/:collectionKey/:id';
export const PRESIGN_OPEN_EDITION_PAGE = '/OpenEdition-Presign/:collectionKey';
