import React, { useCallback, useEffect, useState } from 'react';

import css from '@styled-system/css';

import { Box, Flex, Icon, Text } from '../atoms';

const CopyButton = ({
  text,
  textDisPlay,
  showIcon,
  width,
  display,
  justifyContent = 'left',
  ...props
}) => {
  const [copied, setCopied] = useState(false);

  const handleClick = useCallback(() => {
    window.navigator.clipboard.writeText(text).then(() => setCopied(true));
  }, [text]);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 1000);
    }
  }, [copied]);

  if (copied) {
    return (
      <Flex
        alignItems="center"
        justifyContent={justifyContent}
        position="relative"
        {...props}
        width={width}
      >
        <Icon name="check" pr="2px" />
        <Text>Copied</Text>
      </Flex>
    );
  }

  return (
    <Box
      css={css({
        ':hover': {
          color: 'text',
        },
      })}
      cursor="pointer"
      {...props}
      justifyContent={display === 'flex' ? 'center' : ''}
      width={width}
      onClick={handleClick}
    >
      {!showIcon && <Text {...props}>{textDisPlay}</Text>}
      {showIcon && <Icon name="copy" size="0.9em" />}
    </Box>
  );
};

export default CopyButton;
