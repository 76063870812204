import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@react-hook/media-query';

import { Box, Flex, Link, Text, Icon } from '@blank/atoms';
import {
  LANDING_ROUTE,
  WHITE_WALL_ROUTE,
  MINT_ROUTE,
  SETTING_ROUTE,
  FEATURED_COLLECTIONS_PAGE_ROUTE,
  FEATURED_CREATORS_PAGE_ROUTE,
} from '@blank/pages/routeConstants';
import { WHITE_WALL_TABS_NAME } from '@blank/constants';
import { truncateAddress } from '@blank/utils';
import { useResetWallet, useAccount } from '@blank/common/WalletContext/hooks';
import ProfileContext, { useUserInfo } from '../ProfileContext';
import CurrentUser from '../CurrentUser';
import CopyButton from '@blank/components/CopyButton';
import Drawer from '@blank/components/Drawer';
import theme from '@blank/theme';
import { useContext } from 'react';
// import { useFetch } from '@blank/hooks/useFetch';

const itemStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  py: '.25em',
  fontFamily: 'normal',
  css: `:hover{ .text {border-bottom: 2px solid;} }`,
};

const scrollStyle = {
  css: {
    ':hover': {
      overflowY: 'auto',
    },
    overflowY: 'hidden',
    overflowX: 'hidden',

    '::-webkit-scrollbar': {
      // width: '7px',
    },
    /* Track */
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      //borderRadius: '7px',
    },
    /* Handle */
    '::-webkit-scrollbar-thumb': {
      background: '#888',
      //borderRadius: '7px',
    },
  },
};

const NavItem = ({ item, onClick, textAlign, ...props }) => {
  //const location = useLocation();
  //const matched = prop('isExact')(matchPath(location.pathname, item.path));
  if (item.hasChild) return item.component;

  return (
    <Link
      to={item.path}
      {...itemStyle}
      {...props}
      //{...(matched && itemHover)}
      onClick={onClick}
      display="block"
      textAlign={textAlign}
    >
      <Text className="text">{item.name}</Text>
    </Link>
  );
};

const USER_NAVS = (account, setOpenDrawer, isMobile, numberNFT) => {
  const mobileMenu = [
    // {
    //   path: EXPLORE_ROUTE,
    //   name: 'Activity',
    //   onClick: () => setOpenDrawer(false),
    // },
    {
      path: FEATURED_COLLECTIONS_PAGE_ROUTE,
      name: 'Collections',
      onClick: () => setOpenDrawer(false),
    },
    {
      path: FEATURED_CREATORS_PAGE_ROUTE,
      name: 'Creators',
      onClick: () => setOpenDrawer(false),
    },
  ];
  const menu = [
    ...(isMobile ? mobileMenu : []),
    {
      path: WHITE_WALL_ROUTE.replace(':account', account).replace(
        ':tab',
        WHITE_WALL_TABS_NAME.profile
      ),
      name: 'My Profile',
      onClick: () => setOpenDrawer(false),
    },
  ];

  return menu;
};

// const OFFER_AUCTION_NAVS = ( setOpenDrawer) => {
//   return [
//     {
//       path: OFFERS,
//       name: 'Offers',
//       onClick: () => setOpenDrawer(false),
//     },
//     {
//       path: AUCTIONS,
//       name: 'Auctions',
//       onClick: () => setOpenDrawer(false),
//     },
//   ];
// };

// const GUEST_CREATOR_NAVS = (setOpenColTypeModal, setOpenDrawer) => {
//   return [
//     {
//       path: MINT_ROUTE,
//       name: 'Create New Work',
//     },
//   ];
// };

const VERIFIED_CREATOR_NAVS = (setOpenColTypeModal, setOpenDrawer) => {
  return [
    {
      path: MINT_ROUTE,
      name: 'Mint New NFT',
    },
    {
      onClick: () => {
        setOpenColTypeModal(true);
        setOpenDrawer(false);
      },
      name: 'Launch New Collection',
    },
  ];
};

// const REGISTERED_CURATOR_NAVS = (setOpenColTypeModal, setOpenDrawer) => {
//   return [
//     {
//       path: '',
//       name: 'Invite',
//     },
//     {
//       onClick: () => {
//         setOpenColTypeModal(true);
//         setOpenDrawer(false);
//       },
//       name: 'Start New Project',
//     },
//   ];
// };

const SlideNav = ({ setOpenColTypeModal }) => {
  const history = useHistory();
  const account = useAccount();
  const resetWallet = useResetWallet();
  const { username, isVerifyArtist } = useUserInfo();
  const [profileState] = useContext(ProfileContext);
  const [navbarWidth, setNavbarWidth] = useState();
  const isMobile = useMediaQuery('only screen and (max-width: 600px)');
  const firstUser = () => !profileState?.user?.register;
  const handleResize = () => {
    const width =
      window.innerWidth <= 600
        ? `${window.innerWidth}px`
        : `${window.innerWidth / 2}px`;
    setNavbarWidth(width);
  };
  const [openDrawer, setOpenDrawer] = useState(false);

  // Needs an api request to add the number of NFT instead of this empty string
  const [numberNFT, setNumberNFT] = useState('');
  // const [getMockData] = useFetch(`/api/assets/profiles?user=${account}`);

  const EDIT_PROFILE_NAV = {
    path: SETTING_ROUTE,
    name: 'Edit Profile',
    onClick: () => setOpenDrawer(false),
  };

  const LOGOUT_NAV = {
    path: 'logout',
    name: 'Log Out',
    onClick: () => setOpenDrawer(false),
  };

  useEffect(() => {
    // getMockData().then((result) => (setNumberNFT(result.assets.length)));
    setNumberNFT('');
  });

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  useEffect(() => {
    handleResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  return (
    <Flex>
      <Icon
        name="burger"
        cursor="pointer"
        position="relative"
        size="32px"
        onClick={() => setOpenDrawer(true)}
      />

      <Drawer
        open={openDrawer}
        width={navbarWidth}
        maxWidth="87vw"
        minHeight="100vh"
        alignItems="flex-start"
        onClickOutside={() => setOpenDrawer(false)}
      >
        <Flex
          flexDirection="column"
          width={navbarWidth}
          position="absolute"
          right="0"
          top="0"
          pt="20px"
          pl={isMobile ? '0px' : '40px'}
          pr={isMobile ? '0px' : '40px'}
          fontWeight="400"
          boxShadow="0px 2px 12px rgba(0, 0, 0, 0.12)"
          color="#757575"
          background="#fff"
          height="100vh"
          pb="100px"
        >
          <Icon
            name="exit-nav-thin"
            cursor="pointer"
            position="absolute"
            right="40px"
            top="40px"
            size="40px"
            onClick={() => setOpenDrawer(false)}
            css={`
              @media (min-width: 320px) and (max-width: 767px) {
                top: 45px;
                right: 25px;
              }
            `}
          />
          <Flex
            width="100%"
            justifyContent={isMobile ? 'center' : 'start'}
            mt="20px"
            css={`
              @media (min-width: 320px) and (max-width: 767px) {
                margin-top: 40px;
              }
            `}
          >
            <CurrentUser.Avatar
              size="75px"
              filter="drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.1))"
              css={`
                @media (min-width: 320px) and (max-width: 767px) {
                  height: 80px;
                  width: 80px;
                }
              `}
            />
          </Flex>
          <Box fontSize={theme.fontSize.large_responsive} mt=".5em">
            <Text
              display="block"
              fontFamily="normal"
              mb=".5em"
              overflow="hidden"
              textOverflow="ellipsis"
              textAlign={isMobile ? 'center' : 'left'}
              color="#000"
            >
              {username ? `${username}` : truncateAddress(account)}
            </Text>

            {username && (
              <CopyButton
                text={account}
                textDisPlay={truncateAddress(account)}
                fontSize={theme.fontSize.small_responsive}
                fontFamily="normal"
                color={theme.colors.textSecondary}
                textAlign={isMobile ? 'center' : 'left'}
                display="flex"
                width="100%"
              />
            )}
          </Box>
          {!firstUser() && (
            <Box
              fontSize={theme.fontSize.sideNav}
              overflowY="auto"
              {...scrollStyle}
              mt={!isVerifyArtist ? '5em' : '1.5em'}
            >
              {USER_NAVS(account, setOpenDrawer, isMobile, numberNFT).map(
                (item, index) => (
                  <NavItem
                    color={theme.colors.text}
                    key={`item${index}`}
                    item={item}
                    onClick={item.onClick}
                    textAlign={isMobile ? 'center' : 'left'}
                  />
                )
              )}
              {isVerifyArtist && (
                <>
                  {VERIFIED_CREATOR_NAVS(
                    setOpenColTypeModal,
                    setOpenDrawer
                  ).map((item, index) => (
                    <NavItem
                      color={theme.colors.secondary}
                      key={`item${index}`}
                      item={item}
                      onClick={item.onClick}
                      textAlign={isMobile ? 'center' : 'left'}
                    />
                  ))}
                </>
              )}
              <Flex
                justifyContent={isMobile ? 'center' : 'left'}
                cursor="pointer"
                alignItems="center"
                onClick={() => {
                  history.push(SETTING_ROUTE);
                  setOpenDrawer(false);
                }}
                css={`
                  :hover {
                    .text {
                      border-bottom: 2px solid;
                    }
                  }
                `}
              >
                <NavItem
                  color={theme.colors.textSecondary}
                  item={EDIT_PROFILE_NAV}
                  textAlign={isMobile ? 'center' : 'left'}
                />
                <Icon
                  color={theme.colors.secondary}
                  name="editProfile-nav-thin"
                  cursor="pointer"
                  size="1em"
                  mx=".5em"
                />
              </Flex>
              <Flex
                width={navbarWidth}
                my="20px"
                borderBottom="1px solid #EBEBEB"
              />
              <Flex
                cursor="pointer"
                justifyContent={isMobile ? 'center' : 'left'}
                alignItems="center"
                position="absolute"
                left="43px"
                right="43px"
                bottom="5vh"
                backgroundColor="#fff"
                onClick={async () => {
                  await resetWallet();
                  history.push(LANDING_ROUTE);
                }}
                css={`
                  @media (max-width: 767px) {
                    bottom: 5vh;
                    right: 25px;
                    left: 25px;
                  }
                  :hover {
                    .text {
                      border-bottom: 2px solid;
                    }
                  }
                `}
              >
                <NavItem
                  color={theme.colors.textSecondary}
                  item={LOGOUT_NAV}
                  textAlign={isMobile ? 'center' : 'left'}
                />
                <Icon name="logOut-nav-thin" size="1em" mx="0.5em" />
              </Flex>
            </Box>
          )}
          {firstUser() && (
            <>
              <Flex
                flexDirection="column"
                fontSize={theme.fontSize.large_responsive}
                mt="15vh"
                width={'70%'}
                maxWidth="450px"
                minWidth="200px"
                mx={isMobile ? 'auto' : '0'}
              >
                <Box mb="4em" mx={isMobile ? 'auto' : '0'}>
                  <Box mb=".5em" textAlign={isMobile ? 'center' : 'left'}>
                    Welcome to Blank !
                  </Box>
                  <Box textAlign={isMobile ? 'center' : 'left'}>
                    Please set-up your username and email to continue exploring
                    the app.
                  </Box>
                </Box>
                <Link
                  textAlign="center"
                  width="100%"
                  to={SETTING_ROUTE}
                  backgroundColor={theme.colors.black}
                  color={theme.colors.white}
                  p=".75em 1em"
                  borderRadius="5px"
                  onClick={() => setOpenDrawer(false)}
                >
                  Set up your profile
                </Link>
              </Flex>
              <Flex
                fontSize={theme.fontSize.sideNav}
                cursor="pointer"
                justifyContent={isMobile ? 'center' : 'left'}
                alignItems="center"
                position="absolute"
                left="43px"
                right="43px"
                bottom="5vh"
                backgroundColor="#fff"
                onClick={async () => {
                  await resetWallet();
                  history.push(LANDING_ROUTE);
                }}
                css={`
                  @media (max-width: 767px) {
                    bottom: 5vh;
                    right: 25px;
                    left: 25px;
                  }
                  :hover {
                    .text {
                      border-bottom: 2px solid;
                    }
                  }
                `}
              >
                <NavItem
                  color={theme.colors.textSecondary}
                  item={LOGOUT_NAV}
                  textAlign={isMobile ? 'center' : 'left'}
                />
                <Icon name="logOut-nav-thin" size="1em" mx="0.5em" />
              </Flex>
            </>
          )}
        </Flex>
      </Drawer>
    </Flex>
  );
};

export default SlideNav;
