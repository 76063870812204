import React from 'react';

import { Flex, Input, Text } from '../atoms';
import FormInput from './FormInput';

const affixStyle = {
  right: '20px',
};

const befixStyle = {
  left: '20px',
};

const ExtendTextComponent = ({ extendText, isBefore, ...props }) => {
  return (
    <Text
      fontWeight="600"
      fontSize={4}
      position="absolute"
      color="primaryLight"
      css={isBefore ? befixStyle : affixStyle}
      {...props}
    >
      {extendText}
    </Text>
  );
};

const FormFieldWithExtend = ({
  Component,
  extendText,
  isBefore,
  wrapperProps,
  wrapperFieldProps,
  extendProps,
  ...props
}) => (
  <Flex center width="100%" {...wrapperProps} position="relative">
    {isBefore && (
      <ExtendTextComponent
        isBefore={isBefore}
        extendText={extendText}
        {...extendProps}
      />
    )}
    <FormInput
      Component={Component || Input}
      wrapperProps={{ width: '100%', ...wrapperFieldProps }}
      borderRadius="8px"
      css={`
        ::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
          appearance: none;
          margin: 0;
        }
        appearance: textfield; /* Firefox */
      `}
      {...props}
    />
    {!isBefore && (
      <ExtendTextComponent
        isBefore={isBefore}
        extendText={extendText}
        {...extendProps}
      />
    )}
  </Flex>
);

export default FormFieldWithExtend;
