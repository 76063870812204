import React, { useEffect, useState } from 'react';
import {
  useWrongNetwork,
  useCheckWaitingLogin,
} from '@blank/common/WalletContext/hooks';
import WrongNetworkModal from './WrongNetworkModal';
import ProcessingModal from '@blank/components/ProcessingModal';

// const checkMetamask = () =>
//   window.web3 && window.web3.currentProvider.isMetaMask;

const LoadingContainer = ({ children }) => {
  const [isLogin, setIsLogin] = useState(true);
  const isWrongNetwork = useWrongNetwork();
  const checkInject = useCheckWaitingLogin();
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: 'eth_accounts' })
        .then((data) => {
          if (data.length === 0) {
            setIsLogin(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (isWrongNetwork) {
    return (
      <>
        <WrongNetworkModal />
        {children}
      </>
    );
  }
  if (checkInject && isLogin) {
    return (
      <>
        <ProcessingModal open children="" />
        {/* {children} */}
      </>
    );
  }
  return children;
};

export default LoadingContainer;
