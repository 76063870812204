// const { IMAGE_DOMAIN } = require('@blank/constants/network');
// this is just a hack for now for the launch

// const mappedSource = [
//   'https://ipfs.blanknetwork.com',
//   'https://ipfs-dev.blanknetwork.com',
// ];

// const createCloudinaryUrl = (ipfsSource = '') => {
//   const cloudinaryUrl = mappedSource.reduce(
//     (target, source) =>
//       target.replace(source, 'https://blank-art.mo.cloudinary.net'),
//     ipfsSource
//   );
//   return cloudinaryUrl;
// };

export const getResizedImage = (width = 200, id, imgSrc) => {
  //returning the source below will use cloudinary for assets hosted on ipfs-dev instead of the resizer
  // const cloudinaryUrl = createCloudinaryUrl(imgSrc);
  // let source = `${cloudinaryUrl}?tx=w_${width},c_fit`;
  // return source;
  return imgSrc;
  //return `${IMAGE_DOMAIN}/${width}/${id}.jpg?url=${imgSrc}`;
};
