import { useCallback } from 'react';
import { useWeb3 } from '@blank/common/WalletContext/hooks/useWeb3';
import { COMPLETE_TRANSACTION, REMOVE_TRANSACTION } from './constants';
import { TRANSACTION_STATUS } from '../constants';
import { isNil } from 'ramda';
import { useGlobalState } from '@blank/globalStateContext';
import { getLastedTransaction } from '@blank/utils';
import { path } from 'ramda';
import abiDecoder from 'abi-decoder';
import { useAccount } from '@blank/common/WalletContext/hooks';
const abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256[]',
        name: 'ids',
        type: 'uint256[]',
      },
      {
        indexed: false,
        internalType: 'string[]',
        name: 'uris',
        type: 'string[]',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'royalty',
        type: 'uint256',
      },
    ],
    name: 'BatchMint',
    type: 'event',
  },
];
abiDecoder.addABI(abi);

const useSubcribeTransactionStatus = (dispatch) => {
  const web3 = useWeb3();
  const account = useAccount();
  const [stateGlobal, dispatchGlobal] = useGlobalState();

  const subcribeTransactionStatus = useCallback(
    (transaction) => {
      if (!transaction?.hash) {
        return;
      }
      const intervalInstance = setInterval(() => {
        web3.eth.getTransaction(transaction.hash, (error, result) => {
          if (error) {
            clearInterval(intervalInstance);
            console.error(
              '[error] get Transaction infomation ',
              transaction.hash,
              ': ',
              error
            );
            return;
          }
          if (!result) {
            // dispatchGlobal({ ...stateGlobal, transactionStatus: 'success' });
            console.log(
              '[Check in localstorage] Transaction is invalid: ',
              transaction.hash
            );
            clearInterval(intervalInstance);
            dispatch({
              type: REMOVE_TRANSACTION,
              payload: transaction,
            });
            setTimeout(() => {
              getLastedTransaction(account).then((data) => {
                web3.eth.getTransactionReceipt(data?.hash, (error, result) => {
                  const decodedLogs = abiDecoder.decodeLogs(result.logs);
                  const ids = path([0, 'events', 1, 'value'])(decodedLogs);
                  const receiptData = {
                    transactionHash: data?.hash,
                    tokenIds: ids || ['1'],
                  };
                  dispatchGlobal({
                    ...stateGlobal,
                    transactionStatus: 'success',
                    receiptData,
                  });
                });
              });
            }, 7000);
            return;
          }
          const transactionIndex = result.transactionIndex;
          if (!isNil(transactionIndex)) {
            clearInterval(intervalInstance);
            web3.eth
              .getTransactionReceipt(transaction.hash)
              .then((resultTx) => {
                const status = resultTx?.status;

                dispatch({
                  type: COMPLETE_TRANSACTION,
                  payload: {
                    transaction,
                    status: status
                      ? TRANSACTION_STATUS.success
                      : TRANSACTION_STATUS.error,
                  },
                });
                setTimeout(() => {
                  dispatch({
                    type: REMOVE_TRANSACTION,
                    payload: transaction,
                  });
                }, 5000);
              })
              .catch(() => {
                dispatch({
                  type: REMOVE_TRANSACTION,
                  payload: transaction,
                });
              });
          }
        });
      }, 1000);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, web3]
  );

  return subcribeTransactionStatus;
};

export default useSubcribeTransactionStatus;
