import React, { useRef } from 'react';

import { useField } from 'formik';

import { Input, Text } from '../atoms';
import InputWrapper from './InputWrapper';
import useFormikFocusFirstError from '../hooks/useFormikFocusFirstError';

const FormInput = ({
  Component = Input,
  inline,
  name,
  label,
  inputRef,
  wrapperProps,
  labelProps,
  extentsText,
  extentsTextProps,
  ExtentsComponent,
  extentsComponentProps,
  maxWidth,
  ...props
}) => {
  const [field] = useField(name);

  const ref = useRef();
  const fieldRef = inputRef || ref;
  useFormikFocusFirstError({ fieldRef, name });

  const input = (
    <Component
      ref={fieldRef}
      maxWidth={maxWidth}
      fontWeight={400}
      label
      {...field}
      {...props}
    />
  );

  return inline ? (
    input
  ) : (
    <InputWrapper
      name={name}
      label={label}
      labelProps={labelProps}
      maxWidth={maxWidth}
      {...wrapperProps}
    >
      {input}
      {ExtentsComponent && ExtentsComponent}
      {extentsText && (
        <Text ml={10} className="extentsText" {...extentsTextProps}>
          {extentsText}
        </Text>
      )}
    </InputWrapper>
  );
};

export default FormInput;
